import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Segment, Grid, GridRow, GridColumn, Button } from "semantic-ui-react";

function InstructorListPage() {
  const [instructors, setInstructors] = useState([]);

  useEffect(() => {
    // 店舗の取得
    const fetchInstructorData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/api/get_instructors/`);
        if (!response.ok) {
          throw new Error("ネットワークレスポンスが異常です。");
        }
        const data = await response.json();
        setInstructors(data);
      } catch (error) {
        console.error("データの取得中にエラーが発生しました:", error);
      }
    };

    fetchInstructorData();
  }, []);

  return (
    <>
      <section>
        <Grid>
          <GridRow>
            {instructors.map((instructor) => (
              <GridColumn className="button-item" key={instructor.id}>
                <Link
                  to={`/website/instructor-edit/${instructor.id}`}
                  className="instructor-link"
                >
                  <Button color="grey">
                    <p>
                      {instructor.dancer_name}
                      <span>({instructor.username})</span>
                    </p>
                  </Button>
                </Link>
              </GridColumn>
            ))}
          </GridRow>
        </Grid>
      </section>
    </>
  );
}

export default InstructorListPage;
