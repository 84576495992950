import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Form,
  Grid,
  Header,
  Input,
  TextArea,
  Icon,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import VisualComponent from "../../../components/website/VisualComponent";
import SortImage from "../../../components/website/SortImage";

const FaqEditPage = () => {
  const { id } = useParams(); // ルートパラメータの`id`を取得
  const navigate = useNavigate();
  const location = useLocation();

  const [parentUpdated, setParentUpdated] = useState(false); // 子コンポーネントで更新が必要な場合にtrueにする

  const [overlay, setOverlay] = useState(false); // オーバーレイの制御
  const [images, setImages] = useState([]); // 画像リスト
  const [checkImages, setCheckImages] = useState([]); // 選択した画像
  const [nowImage, setNowImage] = useState([]); // 現在選択中の画像

  // GETパラメータにカテゴリーIDがある場合は取得（新規の場合）
  const query = new URLSearchParams(location.search);
  const category_id = query.get("category");

  const [commonQuestion, setCommonQuestion] = useState(null);

  const [page, setPage] = useState(1); // ページネーション用
  const [searchWord, setSearchWord] = useState(""); // 検索文字

  // 子コンポーネントに渡して更新を検知する関数
  const handleUpdate = useCallback(() => {
    // 更新が必要なときに実行する処理
    fetchImagesData();
    fetchCommonQuestionData();
  }, []);

  // よくある質問の取得
  const fetchCommonQuestionData = useCallback(async () => {
    if (!id) return;
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_common_question/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setCommonQuestion(data);
      setCheckImages(data.images);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, [id]);

  // カテゴリーの取得
  const fetchQuestionCategoryData = useCallback(async () => {
    if (!category_id) return;
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_question_category/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: category_id,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setCommonQuestion((prev) => ({
        ...prev,
        id: null,
        category_id: data.id,
        category_text: data.text,
        images: [],
      }));
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, [category_id]);

  // 画像リストの取得
  const fetchImagesData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_images/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          page: page,
          search_word: searchWord,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      if (page === 1) {
        setImages(data);
      } else {
        setImages((prevImages) => {
          const newImages = data.filter(
            (newImage) =>
              !prevImages.some((prevImage) => prevImage.id === newImage.id)
          );
          return [...prevImages, ...newImages];
        });
      }
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, [page]);

  // 画像の検索
  const searchImagesData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_images/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          page: 1,
          search_word: searchWord,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setImages(data);
      setPage(1);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  // inputタグが更新された時にstateを更新する関数
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCommonQuestion((prev) => ({ ...prev, [name]: value }));
  };

  // 検索欄の状態を監視
  const handleSearchChange = (event) => {
    setSearchWord(event.target.value);
  };

  // submitボタンが押下された時に動く関数
  const handleSubmit = async (e) => {
    e.preventDefault();
    setParentUpdated(false);
    const formData = new FormData();

    formData.append("id", commonQuestion.id);
    formData.append("question", commonQuestion.question);
    formData.append("answer", commonQuestion.answer);
    formData.append("category_id", commonQuestion.category_id);
    const imageIds = checkImages.map((image) => image.id).join(",");
    formData.append("image_ids", imageIds);

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/save_common_question/`, {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const newPath = `/website/faq-edit/${data.faq.id}`;
      if (location.pathname === newPath) {
        // 既に必要なページにいる場合、stateを更新
        fetchCommonQuestionData();
        fetchImagesData();
        setNowImage([]);
        setParentUpdated(true);
        alert("更新しました");
      } else {
        // 新規作成ページにいる場合、編集ページへ遷移
        alert("保存しました");
        navigate(newPath);
      }
    } catch (error) {
      alert("登録に失敗しました");
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // 画像選択時にstateにセット
  const handleCheckImages = (image) => {
    // 現在選択中の画像にセット
    setNowImage(image);

    setCheckImages((prev) => {
      // 既に同じ画像が存在するか確認
      const isImageAlreadyAdded = prev.some(
        (prevImage) => prevImage.id === image.id
      );
      if (!isImageAlreadyAdded) {
        // 画像が存在しない場合、追加
        return [...prev, image];
      }
      // 画像が既に存在する場合、その画像をstateから削除
      return prev.filter((prevImage) => prevImage.id !== image.id);
    });
  };

  // オーバーレイの表示/非表示
  const toggleOverlay = (bool) => {
    setOverlay(bool);
  };

  // ビデオかどうか
  const isVideo = (url) => {
    // 動画ファイルの拡張子リスト
    const videoExtensions = [".mp4", ".webm", ".ogg"];
    // URLの末尾の拡張子を確認
    return videoExtensions.some((extension) => url.endsWith(extension));
  };

  // よくある質問の取得
  useEffect(() => {
    fetchCommonQuestionData();
  }, [fetchCommonQuestionData]);

  // カテゴリーの取得
  useEffect(() => {
    fetchQuestionCategoryData();
  }, [fetchQuestionCategoryData]);

  // 画像リストの取得
  useEffect(() => {
    fetchImagesData();
  }, [fetchImagesData]);

  return (
    <>
      {/* 画像オーバーレイ */}
      <div className={overlay ? "overlay open" : "overlay"}>
        <div className="ov grid2">
          <Icon
            name="times circle"
            className="cancelBtn"
            onClick={() => {
              toggleOverlay(false);
            }}
          />
          {/* 左カラム */}
          <div className="imgArea overflow">
            <div className="searchWrap">
              <Input
                icon={{
                  name: "search",
                  link: true,
                  onClick: () => searchImagesData(),
                }}
                placeholder="検索"
                onChange={handleSearchChange}
              />
            </div>
            <ul className="imgList">
              {images.map(
                (image) =>
                  !isVideo(image.url) && (
                    <li
                      key={image.id}
                      onClick={() => {
                        handleCheckImages(image);
                      }}
                    >
                      <Icon
                        name="check circle"
                        className={
                          checkImages.some(
                            (checkImage) => checkImage.id === image.id
                          )
                            ? "on"
                            : ""
                        }
                      />
                      <img src={image.url} alt="" />
                    </li>
                  )
              )}
            </ul>
            <div className="loadBtn">
              <Button
                primary
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                さらに読み込む
              </Button>
            </div>
          </div>
          {/* 右カラム */}
          <div className="hashtagData">
            <div className="imgBox">
              {/* 現在、選択中の画像 */}
              <div className="nowImg">
                <p>現在、選択中の画像</p>
                {nowImage && (
                  <>
                    <figure>
                      <img src={nowImage.url} alt="" />
                    </figure>
                    <ul className="hashtagArea">
                      {nowImage?.hashtags?.map((hashtag) => (
                        <li key={hashtag.id}>{hashtag.text}</li>
                      ))}
                    </ul>
                  </>
                )}
              </div>

              {/* 全ての選択した画像 */}
              <div className="selectedImg">
                <ul className="imgList">
                  {checkImages.map((image) => (
                    <li key={image.id}>
                      <img src={image.url} alt="" />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <Button
              primary
              className="addImg"
              onClick={() => {
                setCommonQuestion((prev) => ({
                  ...prev,
                  images: checkImages,
                }));
                setOverlay(false);
              }}
            >
              追加
            </Button>
          </div>
        </div>
      </div>

      {/* 左セクション */}
      <section>
        <div className="content-header">
          <div className="button-group">
            <Button primary onClick={handleSubmit}>
              {id ? "更新" : "作成"}
            </Button>
          </div>
        </div>
        <Grid container stackable>
          {/* カテゴリー */}
          <Grid.Row columns={1}>
            <Grid.Column>
              <p className="form-label">カテゴリー</p>
              <Header as="h4">{commonQuestion?.category_text}</Header>
            </Grid.Column>
          </Grid.Row>

          {/* 質問 */}
          <Grid.Row columns={1}>
            <Grid.Column>
              <p className="form-label">質問</p>
              <Input
                fluid
                placeholder="質問"
                name="question"
                value={commonQuestion?.question}
                onChange={handleInputChange}
              />
            </Grid.Column>
          </Grid.Row>

          {/* 回答 */}
          <Grid.Row columns={1}>
            <Grid.Column>
              <p className="form-label">回答</p>
              <Form>
                <TextArea
                  placeholder="回答"
                  name="answer"
                  value={commonQuestion?.answer}
                  rows={10}
                  onChange={handleInputChange}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>

          {/* 画像 */}
          <Grid.Row columns={1}>
            <Grid.Column>
              <p className="form-label">画像</p>
              <SortImage
                checkImages={checkImages}
                setCheckImages={setCheckImages}
              />
            </Grid.Column>
          </Grid.Row>

          {/* 画像追加ボタン */}
          <Grid.Row columns={1}>
            <Grid.Column>
              <Button
                primary
                onClick={() => {
                  toggleOverlay(true);
                }}
              >
                画像を追加
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </section>

      {/* 右セクション */}
      <section>
        <VisualComponent
          onUpdate={handleUpdate}
          hashtagText={id ? `faq_${id}` : null}
          parentUpdated={parentUpdated}
        />
      </section>
    </>
  );
};

export default FaqEditPage;
