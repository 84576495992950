import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Icon,
  Label,
  Segment,
  Input,
  TextArea,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import VisualComponent from "../../../components/website/VisualComponent";
import PriceArea from "../../../components/website/campaign/PriceArea";
import SortImage from "../../../components/website/SortImage";

const CampaignEditPage = () => {
  const { id } = useParams(); // ルートパラメータの`id`を取得
  const navigate = useNavigate();
  const location = useLocation();

  const [campaign, setCampaign] = useState({
    id: null,
    slug: "",
    title: "",
    text: "",
    price_title: "",
    images: [],
  });
  const [prices, setPrices] = useState([{ name: "", before: "", after: "" }]);

  const [page, setPage] = useState(1); // ページネーション用
  const [searchWord, setSearchWord] = useState(""); // 検索文字

  const [parentUpdated, setParentUpdated] = useState(false); // 子コンポーネントで更新が必要な場合にtrueにする

  const [overlay, setOverlay] = useState(false); // オーバーレイの制御
  const [images, setImages] = useState([]); // 画像リスト
  const [checkImages, setCheckImages] = useState([]); // 選択した画像
  const [nowImage, setNowImage] = useState([]); // 現在選択中の画像

  //セレクトボックスオプション
  const [selectOptions, setSelectOptions] = useState([
    { key: 0, value: 0, text: "なし" },
  ]);

  //チェックボックスのオプション
  const [checkOptions, setCheckOptions] = useState([
    { key: 0, value: 0, text: "全店舗" },
  ]);

  const [checkedStudios, setCheckedStudios] = useState([]); // チェックされているスタジオ

  // スタジオリストの取得
  const fetchStudiosData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_studios/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      const newOptions = data.map((studio) => ({
        key: studio.id,
        value: studio.id,
        text: studio.name,
      }));
      // セレクトボックス用のオプションに格納
      setSelectOptions((prevOptions) => {
        const updatedOptions = [...prevOptions];
        newOptions.forEach((newOption) => {
          if (!updatedOptions.some((option) => option.key === newOption.key)) {
            updatedOptions.push(newOption);
          }
        });
        return updatedOptions;
      });
      // チェックボックス用のオプションに格納
      setCheckOptions((prevStudios) => {
        const updatedStudios = [...prevStudios];
        newOptions.forEach((newStudio) => {
          if (!updatedStudios.some((option) => option.key === newStudio.key)) {
            updatedStudios.push(newStudio);
          }
        });
        return updatedStudios;
      });
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  useEffect(() => {
    fetchStudiosData();
  }, [fetchStudiosData]);

  // ドロップダウン変更
  const handleSelectChange = (e, { name, value }) => {
    setCampaign((prevCampaign) => ({
      ...prevCampaign,
      [name]: value,
    }));
  };

  // スタジオチェックボックス変更
  const handleCheckedStudios = (studioId) => {
    setCheckedStudios((prevStudios) => {
      if (studioId === 0) {
        return [0];
      } else if (prevStudios.includes(studioId)) {
        return prevStudios.filter((id) => id !== studioId);
      } else {
        let newStudios = [...prevStudios, studioId];
        if (newStudios.includes(0)) {
          newStudios = newStudios.filter((id) => id !== 0);
        }
        return newStudios;
      }
    });
  };

  // 子コンポーネントに渡して更新を検知する関数
  const handleUpdate = useCallback(() => {
    // 更新が必要なときに実行する処理
    fetchImagesData();
    fetchCampaignData();
  }, []);

  // 画像リストの取得
  const fetchImagesData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_images/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          page: page,
          search_word: searchWord,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      if (page === 1) {
        setImages(data);
      } else {
        setImages((prevImages) => {
          const newImages = data.filter(
            (newImage) =>
              !prevImages.some((prevImage) => prevImage.id === newImage.id)
          );
          return [...prevImages, ...newImages];
        });
      }
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, [page]);

  // キャンペーンの取得
  const fetchCampaignData = useCallback(async () => {
    if (!id) return;
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_campaign/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      // price_detailsをパース
      data.price_details = JSON.parse(data.price_details);
      setCampaign(data);
      if (data.target_studios !== null) {
        setCheckedStudios(JSON.parse(data.target_studios));
      }
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, [id]);

  // inputタグが更新された時にstateを更新する関数
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCampaign((prev) => ({ ...prev, [name]: value }));
  };

  // submitボタンが押下された時に動く関数
  const handleSubmit = async (e) => {
    e.preventDefault();

    // バリデーション
    if (campaign.slug === "") {
      alert("エラー: スラッグは必須です。");
      return;
    }
    if (campaign.price_title === "") {
      alert("エラー: 値下げタイトルは必須です。");
      return;
    }
    if (
      prices[0].name === "" ||
      prices[0].before === "" ||
      prices[0].after === ""
    ) {
      alert("エラー: 値下げ詳細は必須です。");
      return;
    }
    if (isNaN(prices[0].before) || isNaN(prices[0].after)) {
      alert("エラー: 値下げ前、値下げ後は数値で入力して下さい。");
      return;
    }
    if (checkImages.length === 0) {
      alert("エラー: 画像は必ず1枚以上選択選択して下さい。");
      return;
    }

    const formData = new FormData();

    formData.append("campaign_id", campaign.id);
    formData.append("slug", campaign.slug);
    formData.append("default_studio", campaign.default_studio);
    formData.append("target_studios", JSON.stringify(checkedStudios));
    formData.append("title", campaign.title);
    formData.append("text", campaign.text);
    formData.append("price_title", campaign.price_title);
    formData.append("price_details", JSON.stringify(prices));
    const imageIds = checkImages.map((image) => image.id).join(",");
    formData.append("image_ids", imageIds);

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/campaign_edit/`, {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      if (!response.ok) {
        const data = await response.json();
        alert(`エラー: ${data.error}`);
        return;
      }

      const data = await response.json();
      const newPath = `/website/campaign-edit/${data.campaign.id}`;
      if (location.pathname === newPath) {
        // 既に必要なページにいる場合、stateを更新
        fetchCampaignData();
        fetchImagesData();
        setNowImage([]);
        setParentUpdated(true);
        alert("更新しました");
      } else {
        // 新規作成ページにいる場合、編集ページへ遷移
        alert("保存しました");
        navigate(newPath);
      }
    } catch (error) {
      alert("登録に失敗しました");
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // 検索欄の状態を監視
  const handleSearchChange = (event) => {
    setSearchWord(event.target.value);
  };

  // 画像の検索
  const searchImagesData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_images/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          page: 1,
          search_word: searchWord,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setImages(data);
      setPage(1);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  // 画像選択時にstateにセット
  const handleCheckImages = (image) => {
    // 現在選択中の画像にセット
    setNowImage(image);

    setCheckImages((prev) => {
      // 既に同じ画像が存在するか確認
      const isImageAlreadyAdded = prev.some(
        (prevImage) => prevImage.id === image.id
      );
      if (!isImageAlreadyAdded) {
        // 画像が存在しない場合、追加
        return [...prev, image];
      }
      // 画像が既に存在する場合、その画像をstateから削除
      return prev.filter((prevImage) => prevImage.id !== image.id);
    });
  };

  // オーバーレイの表示/非表示
  const toggleOverlay = (bool) => {
    setOverlay(bool);
  };

  // ビデオかどうか
  const isVideo = (url) => {
    // 動画ファイルの拡張子リスト
    const videoExtensions = [".mp4", ".webm", ".ogg"];
    // URLの末尾の拡張子を確認
    return videoExtensions.some((extension) => url.endsWith(extension));
  };

  // キャンペーンの取得
  useEffect(() => {
    fetchCampaignData();
  }, [fetchCampaignData]);

  // 画像リストの取得
  useEffect(() => {
    fetchImagesData();
  }, [fetchImagesData]);

  useEffect(() => {
    if (JSON.stringify(campaign.images) !== JSON.stringify(checkImages)) {
      setCheckImages(campaign.images);
    }

    if (JSON.stringify(campaign.price_details) !== JSON.stringify(prices)) {
      if (campaign.price_details === undefined) return;

      setPrices(campaign.price_details);
    }
  }, [campaign]);

  return (
    <>
      {/* 画像オーバーレイ */}
      <div className={overlay ? "overlay open" : "overlay"}>
        <div className="ov grid2">
          <Icon
            name="times circle"
            className="cancelBtn"
            onClick={() => {
              toggleOverlay(false);
            }}
          />
          {/* 左カラム */}
          <div className="imgArea overflow">
            <div className="searchWrap">
              <Input
                icon={{
                  name: "search",
                  link: true,
                  onClick: () => searchImagesData(),
                }}
                placeholder="検索"
                onChange={handleSearchChange}
              />
            </div>
            <ul className="imgList">
              {images.map(
                (image) =>
                  !isVideo(image.url) && (
                    <li
                      key={image.id}
                      onClick={() => {
                        handleCheckImages(image);
                      }}
                    >
                      <Icon
                        name="check circle"
                        className={
                          checkImages.some(
                            (checkImage) => checkImage.id === image.id
                          )
                            ? "on"
                            : ""
                        }
                      />
                      <img src={image.url} alt="" />
                    </li>
                  )
              )}
            </ul>
            <div className="loadBtn">
              <Button
                primary
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                さらに読み込む
              </Button>
            </div>
          </div>
          {/* 右カラム */}
          <div className="hashtagData">
            <div className="imgBox">
              {/* 現在、選択中の画像 */}
              <div className="nowImg">
                <p>現在、選択中の画像</p>
                {nowImage && (
                  <>
                    <figure>
                      <img src={nowImage.url} alt="" />
                    </figure>
                    <ul className="hashtagArea">
                      {nowImage?.hashtags?.map((hashtag) => (
                        <li key={hashtag.id}>{hashtag.text}</li>
                      ))}
                    </ul>
                  </>
                )}
              </div>

              {/* 全ての選択した画像 */}
              <div className="selectedImg">
                <ul className="imgList">
                  {checkImages.map((image) => (
                    <li key={image.id}>
                      <img src={image.url} alt="" />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <Button
              primary
              className="addImg"
              onClick={() => {
                setCampaign((prev) => ({
                  ...prev,
                  images: checkImages,
                }));
                setOverlay(false);
              }}
            >
              追加
            </Button>
          </div>
        </div>
      </div>

      {/* 左セクション */}
      <section>
        <div className="content-header">
          <div className="button-group">
            <Button primary onClick={handleSubmit}>
              {id ? "更新" : "作成"}
            </Button>
          </div>
        </div>
        <Grid container stackable>
          <Grid.Row columns={2}>
            {/* スラッグ */}
            <Grid.Column>
              <p className="form-label">スラッグ</p>
              <Input
                fluid
                placeholder="スラッグ"
                name="slug"
                value={campaign?.slug}
                onChange={handleInputChange}
              />
            </Grid.Column>
            {/* デフォルト店舗 */}
            <Grid.Column>
              <p className="form-label">デフォルト店舗</p>
              <Dropdown
                name="default_studio"
                fluid
                selection
                options={selectOptions}
                onChange={handleSelectChange}
                value={parseInt(campaign.default_studio)}
              />
            </Grid.Column>
          </Grid.Row>

          {/* 対象店舗 */}
          <Grid.Row columns={1}>
            <Grid.Column>
              <p className="form-label">対象店舗</p>
              <div className="studioList">
                {checkOptions.map((option) => (
                  <Label
                    className={
                      checkedStudios?.includes(option.value)
                        ? "checkItem on"
                        : "checkItem"
                    }
                    key={option.key}
                    as="a"
                    onClick={() => handleCheckedStudios(option.value)}
                  >
                    {option.text}
                  </Label>
                ))}
              </div>
            </Grid.Column>
          </Grid.Row>

          {/* タイトル */}
          <Grid.Row columns={1}>
            <Grid.Column>
              <p className="form-label">タイトル</p>
              <Form>
                <TextArea
                  placeholder="タイトル"
                  name="title"
                  value={campaign?.title}
                  rows={3}
                  onChange={handleInputChange}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>

          {/* 本文 */}
          <Grid.Row columns={1}>
            <Grid.Column>
              <p className="form-label">本文</p>
              <Form>
                <TextArea
                  placeholder="本文"
                  name="text"
                  value={campaign?.text}
                  rows={3}
                  onChange={handleInputChange}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>

          {/* 料金 */}
          <Grid.Row columns={1}>
            {/* 料金タイトル */}
            <Grid.Column>
              <p className="form-label">値下げタイトル</p>
              <Input
                fluid
                placeholder="料金タイトル"
                name="price_title"
                value={campaign?.price_title}
                onChange={handleInputChange}
              />
            </Grid.Column>
          </Grid.Row>

          {/* 値下げ */}
          <Grid.Row columns={1}>
            {/* 料金タイトル */}
            <Grid.Column>
              <p className="form-label">値下げ詳細</p>
              <PriceArea prices={prices} setPrices={setPrices} />
            </Grid.Column>
          </Grid.Row>

          {/* 画像 */}
          <Grid.Row columns={1}>
            <Grid.Column>
              <p className="form-label">画像</p>
              <SortImage
                checkImages={checkImages}
                setCheckImages={setCheckImages}
              />
            </Grid.Column>
          </Grid.Row>

          {/* 画像追加ボタン */}
          <Grid.Row columns={1}>
            <Grid.Column>
              <Button
                primary
                onClick={() => {
                  toggleOverlay(true);
                }}
              >
                画像を追加
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </section>

      {/* 右セクション */}
      <section>
        <VisualComponent
          onUpdate={handleUpdate}
          hashtagText={id ? `campaign_${id}` : null}
          parentUpdated={parentUpdated}
        />
      </section>
    </>
  );
};

export default CampaignEditPage;
