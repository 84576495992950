import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Icon,
  Input,
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Table,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { format } from "date-fns";
import { formatStudioName } from "../../../utils/formatStudioName";
import jaLocale from "@fullcalendar/core/locales/ja";

function LessonEditPage() {
  const { studioId } = useParams();
  const [studioName, setStudioName] = useState("");
  const [lessons, setLessons] = useState([]);
  const [cancelLessons, setCancelLessons] = useState([]);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [hoveredCancelId, setHoveredCancelId] = useState("");
  const [selectedCancelId, setSelectedCancelId] = useState("");
  const [calendarApi, setCalendarApi] = useState(null);
  const [overlay, setOverlay] = useState(false);
  // 休校日
  const [cancellationDate, setCancellationDate] = useState();
  const [rescheduledDate, setRescheduledDate] = useState();

  // 休講データの取得
  const fetchCancelLessonData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_cancel_lessons/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          studio_id: studioId,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
      setStudioName(data.lessons[0].studio_name);
      setLessons(data.lessons);
      setCancelLessons(data.cancel_lessons);
    } catch (error) {
      console.error("詳細の取得に失敗しました", error);
    }
  }, [studioId]);

  // 休講データを更新
  const handleCancelLessonDataSubmit = async () => {
    console.log({
      cancel_id: selectedCancelId,
      cancellation_date: cancellationDate,
      rescheduled_date: rescheduledDate,
    });
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/update_cancel_lesson/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cancel_id: selectedCancelId,
          cancellation_date: cancellationDate,
          rescheduled_date: rescheduledDate,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      alert("休校情報を更新しました");
      console.log(data);
      fetchCancelLessonData();
      setOverlay(false);
    } catch (error) {
      alert("更新に失敗しました");
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  useEffect(() => {
    // レッスンデータのフォーマット
    function formatEvents(cancelData) {
      // 通常のレッスン(1〜4週)
      const lesson_data = [];
      const dayOfWeekMapping = {
        sunday: 0,
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
      };
      lessons.map((lesson) => {
        const dayOfWeek = dayOfWeekMapping[lesson.day_of_week];
        const month = selectedDay.getMonth();
        const year = selectedDay.getFullYear();
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        let count = 0;
        for (let i = 1; i <= daysInMonth; i++) {
          const date = new Date(year, month, i);
          // 曜日が一致した場合
          if (date.getDay() === dayOfWeek) {
            count++;
            // 4週目まで
            if (count <= 4) {
              lesson_data.push({
                date: format(date, "yyyy-MM-dd"),
                className: "open",
              });
            }
          }
        }
      });

      // 日付が重複しているデータを削除
      const uniqueLessonData = removeDuplicates(lesson_data, "date");

      // 休講情報を反映
      cancelData.forEach((cancelLesson) => {
        // キャンセル日と一致するデータを取得
        const matchingLesson = uniqueLessonData.find(
          (lesson) => lesson.date === cancelLesson.cancellation_date
        );
        // キャンセル
        if (matchingLesson) {
          matchingLesson.className = `close cancel-id-${cancelLesson.id}`;
        } else {
          const cancellationDate = new Date(cancelLesson.cancellation_date);
          if (
            cancellationDate.getMonth() === selectedDay.getMonth() &&
            cancellationDate.getFullYear() === selectedDay.getFullYear()
          ) {
            uniqueLessonData.push({
              date: cancelLesson.cancellation_date,
              className: `close cancel-id-${cancelLesson.id}`,
            });
          }
        }
        // 振替
        if (cancelLesson.rescheduled_date) {
          const rescheduledDate = new Date(cancelLesson.rescheduled_date);
          if (
            rescheduledDate.getMonth() === selectedDay.getMonth() &&
            rescheduledDate.getFullYear() === selectedDay.getFullYear()
          ) {
            uniqueLessonData.push({
              date: cancelLesson.rescheduled_date,
              className: `open cancel-id-${cancelLesson.id}`,
            });
          }
        }
      });

      // 重複削除
      function removeDuplicates(originalArray, prop) {
        const newArray = [];
        const lookupObject = {};
        for (var i in originalArray) {
          lookupObject[originalArray[i][prop]] = originalArray[i];
        }
        for (i in lookupObject) {
          newArray.push(lookupObject[i]);
        }
        return newArray;
      }

      setEvents(uniqueLessonData);
    }

    formatEvents(cancelLessons);
  }, [selectedDay, cancelLessons]);

  useEffect(() => {
    fetchCancelLessonData();
  }, [fetchCancelLessonData]);

  useEffect(() => {
    const updateCalendarCells = () => {
      const cells = document.querySelectorAll(".fc-daygrid-day");
      cells.forEach((cell) => {
        const events = cell.querySelectorAll(".fc-event");
        let hasMatchingEvent = false;
        events.forEach((event) => {
          if (event.classList.contains(`cancel-id-${hoveredCancelId}`)) {
            hasMatchingEvent = true;
          }
        });
        if (hasMatchingEvent) {
          cell.classList.add("on");
        } else {
          cell.classList.remove("on");
        }

        // ホバー時
        cell.addEventListener("mouseover", (e) => {
          const event = cell.querySelector(".fc-event");
          if (event) {
            const cancelIdMatch = event.className.match(/cancel-id-(\d+)/);
            if (cancelIdMatch) {
              const cancelId = cancelIdMatch[1];
              setHoveredCancelId(cancelId);
            }
          }
        });
        cell.addEventListener("mouseout", () => {
          setHoveredCancelId(null);
        });
      });
    };

    updateCalendarCells();
  }, [hoveredCancelId, events]);

  useEffect(() => {
    if (calendarApi) {
      calendarApi.gotoDate(selectedDay);
    }
  }, [selectedDay, calendarApi]);

  return (
    <>
      <div
        className={overlay ? "overlay open" : "overlay"}
        onClick={() => {
          setOverlay(false);
        }}
      >
        <div
          className="ov"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Icon
            name="times circle"
            className="cancelBtn"
            onClick={() => {
              setOverlay(false);
            }}
          />
          <ul>
            <li>
              <p className="form-label">休校日</p>
              <Input
                type="date"
                name="cancellation_date"
                value={cancellationDate || ""}
                onChange={(e) => setCancellationDate(e.target.value)}
              />
            </li>
            <li>
              <p className="form-label">振替日</p>
              <Input
                type="date"
                name="rescheduled_date"
                value={rescheduledDate || ""}
                onChange={(e) => setRescheduledDate(e.target.value)}
              />
            </li>
          </ul>
          <Button
            primary
            onClick={() => {
              handleCancelLessonDataSubmit();
            }}
          >
            保存
          </Button>
        </div>
      </div>

      {/* 左セクション */}
      <section>
        {/* ヘッダー */}
        <div className="content-header">
          <h3>{studioName && formatStudioName(studioName)}</h3>
        </div>
        <FullCalendar
          ref={(calendar) => {
            if (calendar) {
              setCalendarApi(calendar.getApi());
            }
          }}
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          initialDate={selectedDay}
          events={events}
          locale={jaLocale}
          dayCellContent={(args) => args.date.getDate()}
          datesSet={(dateInfo) => {
            setSelectedDay(new Date(dateInfo.view.currentStart));
          }}
        />
      </section>

      {/* 右セクション */}
      {cancelLessons && cancelLessons.length > 0 && (
        <section>
          <Table celled inverted selectable className="schedule-list">
            <TableHeader>
              <TableRow>
                <TableHeaderCell>休校日</TableHeaderCell>
                <TableHeaderCell>振替日</TableHeaderCell>
                <TableHeaderCell>備考</TableHeaderCell>
                <TableHeaderCell>イントラ</TableHeaderCell>
                <TableHeaderCell></TableHeaderCell>
              </TableRow>
            </TableHeader>

            <TableBody>
              {cancelLessons.map((cancelLesson) => (
                <TableRow
                  key={cancelLesson.id}
                  className={cancelLesson.id == hoveredCancelId ? "on" : ""}
                  onMouseOver={() => setHoveredCancelId(cancelLesson.id)}
                  onMouseLeave={() => setHoveredCancelId(null)}
                >
                  <TableCell
                    className={cancelLesson.cancellation_date ? "pointer" : ""}
                    onClick={() =>
                      setSelectedDay(new Date(cancelLesson.cancellation_date))
                    }
                  >
                    {cancelLesson.cancellation_date}
                  </TableCell>
                  <TableCell
                    className={cancelLesson.rescheduled_date ? "pointer" : ""}
                    onClick={
                      cancelLesson.rescheduled_date
                        ? () =>
                            setSelectedDay(
                              new Date(cancelLesson.rescheduled_date)
                            )
                        : null
                    }
                  >
                    {cancelLesson.rescheduled_date
                      ? cancelLesson.rescheduled_date
                      : "振替なし"}
                  </TableCell>
                  <TableCell>{cancelLesson.message}</TableCell>
                  <TableCell>{cancelLesson.instructor}</TableCell>
                  <TableCell>
                    <Button
                      primary
                      onClick={() => {
                        setCancellationDate(cancelLesson.cancellation_date);
                        setRescheduledDate(cancelLesson.rescheduled_date);
                        setSelectedCancelId(cancelLesson.id);
                        setOverlay(true);
                      }}
                    >
                      編集
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </section>
      )}
    </>
  );
}

export default LessonEditPage;
