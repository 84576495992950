import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Form,
  Grid,
  Icon,
  Image,
  Input,
  TextArea,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import VisualComponent from "../../../components/website/VisualComponent";
import SortImage from "../../../components/website/SortImage";

function InstructorEditPage() {
  const { instructorId } = useParams();
  const [instructor, setInstructor] = useState({
    dancer_name: "",
    instagram: "",
    movies: [],
    profile: "",
    images: [],
    main_image: [],
  });

  // 子コンポーネントに渡して更新を検知する関数
  const handleUpdate = useCallback(() => {
    // 更新が必要なときに実行する処理
    fetchInstructorData();
    fetchImagesData();
  }, []);

  const [overlay, setOverlay] = useState(false); // オーバーレイの制御
  const [images, setImages] = useState([]); // 画像リスト
  const [checkImages, setCheckImages] = useState([]); // 選択した画像
  const [nowImage, setNowImage] = useState([]); // 現在選択中の画像

  const [overlayMode, setOverlayMode] = useState(null); // オーバーレイのモードを制御
  const [mainImage, setMainImage] = useState([]); // 選択したアイキャッチ画像

  const [page, setPage] = useState(1); // ページネーション用
  const [searchWord, setSearchWord] = useState(""); // 検索文字

  // 入力フォーム変更
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInstructor((prevInstructor) => ({
      ...prevInstructor,
      [name]: value,
    }));
  };

  // YouTube IDを変更
  const handleMovieChange = (index, value) => {
    setInstructor((prevInstructor) => {
      const updatedMovies = Array.isArray(prevInstructor.movies)
        ? [...prevInstructor.movies]
        : [];
      // 既存の項目を更新するか、新しい項目を追加する
      if (index < updatedMovies.length) {
        updatedMovies[index] = value;
      } else {
        updatedMovies.push(value);
      }
      return {
        ...prevInstructor,
        movies: updatedMovies,
      };
    });
  };

  // YouTube IDを削除
  const handleMovieDelete = (index) => {
    const updatedMovies = [...instructor.movies];
    updatedMovies.splice(index, 1);
    setInstructor({
      ...instructor,
      movies: updatedMovies,
    });
  };

  // YouTube IDを追加
  const handleMovieAdd = () => {
    setInstructor((prevInstructor) => {
      const updatedMovies = Array.isArray(prevInstructor.movies)
        ? [...prevInstructor.movies]
        : [];
      return {
        ...prevInstructor,
        movies: [...updatedMovies, ""],
      };
    });
  };

  // インストラクター情報を更新
  const handleInstructorDataSubmit = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const imageIds = checkImages.map((image) => image.id).join(",");
      const mainImageIds = instructor.main_image
        .map((image) => image.id)
        .join(",");
      const response = await fetch(`${apiUrl}/api/update_instructor/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          instructor_id: instructorId,
          dancer_name: instructor.dancer_name,
          profile: instructor.profile,
          instagram: instructor.instagram,
          movies: instructor.movies,
          image_ids: imageIds,
          main_image_ids: mainImageIds,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      alert("インストラクター情報を更新しました");
      console.log(data);
    } catch (error) {
      alert("更新に失敗しました");
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // インストラクターデータの取得
  const fetchInstructorData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_instructor/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          instructor_id: instructorId,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setInstructor(data);
      setCheckImages(data.images);
      setMainImage(data.main_image);
    } catch (error) {
      console.error("詳細の取得に失敗しました", error);
    }
  }, [instructorId]);

  useEffect(() => {
    fetchInstructorData();
  }, [fetchInstructorData]);

  // YouTube ID入力行をレンダリング
  const renderMovieInput = (movie, index) => {
    return (
      <ul key={index}>
        <li className="youtube-id">
          <div className="input-action-wrapper">
            <Input
              className="youtube-id-input"
              name="youtube-id"
              value={movie || ""}
              onChange={(e) => handleMovieChange(index, e.target.value)}
            />
            <div className="action-buttons">
              <Button
                icon="plus circle"
                className="add-youtube-button"
                onClick={handleMovieAdd}
              />
              <Button
                icon="minus circle"
                className="delete-youtube-button"
                onClick={() => handleMovieDelete(index)}
              />
            </div>
          </div>
        </li>
      </ul>
    );
  };

  // 既存のYouTube ID入力行またはデフォルトのYouTube ID入力行をレンダリング
  const movieInputs =
    instructor.movies && instructor.movies.length > 0
      ? instructor.movies.map(renderMovieInput)
      : renderMovieInput("", 0);

  // ビデオかどうか
  function isVideo(url) {
    // 動画ファイルの拡張子リスト
    const videoExtensions = [".mp4", ".webm", ".ogg"];
    // URLの末尾の拡張子を確認
    return videoExtensions.some((extension) => url.endsWith(extension));
  }

  // オーバーレイの表示/非表示
  const toggleOverlay = (bool) => {
    setOverlay(bool);
  };

  // 検索欄の状態を監視
  const handleSearchChange = (event) => {
    setSearchWord(event.target.value);
  };

  // 画像選択時にstateにセット
  const handleCheckImages = (image) => {
    // 現在選択中の画像にセット
    setNowImage(image);

    setCheckImages((prev) => {
      // 既に同じ画像が存在するか確認
      const isImageAlreadyAdded = prev.some(
        (prevImage) => prevImage.id === image.id
      );
      if (!isImageAlreadyAdded) {
        // 画像が存在しない場合、追加
        return [...prev, image];
      }
      // 画像が既に存在する場合、その画像をstateから削除
      return prev.filter((prevImage) => prevImage.id !== image.id);
    });
  };

  // 画像リストの取得
  const fetchImagesData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_images/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          page: page,
          search_word: searchWord,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      if (page === 1) {
        setImages(data);
      } else {
        setImages((prevImages) => {
          const newImages = data.filter(
            (newImage) =>
              !prevImages.some((prevImage) => prevImage.id === newImage.id)
          );
          return [...prevImages, ...newImages];
        });
      }
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, [page]);

  // 画像リストの取得
  useEffect(() => {
    fetchImagesData();
  }, [fetchImagesData]);

  // 画像の検索
  const searchImagesData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_images/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          page: 1,
          search_word: searchWord,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setImages(data);
      setPage(1);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  // メイン画像選択時にstateにセット
  const handleCheckMainImage = (image) => {
    // 現在選択中の画像にセット
    setNowImage(image);

    setMainImage((prev) => {
      // 既に同じ画像が存在するか確認
      const isImageAlreadyAdded = prev.some(
        (prevImage) => prevImage.id === image.id
      );
      if (!isImageAlreadyAdded) {
        // 画像が存在しない場合、入れ替え
        return [image];
      }
      // 画像が既に存在する場合、その画像をstateから削除
      return prev.filter((prevImage) => prevImage.id !== image.id);
    });
  };

  useEffect(() => {
    console.log(instructor);
  }, [instructor]);

  return (
    <>
      {/* 画像オーバーレイ */}
      <div className={overlay ? "overlay open" : "overlay"}>
        <div className="ov grid2">
          <Icon
            name="times circle"
            className="cancelBtn"
            onClick={() => {
              toggleOverlay(false);
            }}
          />
          {/* 左カラム */}
          <div className="imgArea overflow">
            <div className="searchWrap">
              <Input
                icon={{
                  name: "search",
                  link: true,
                  onClick: () => searchImagesData(),
                }}
                placeholder="検索"
                onChange={handleSearchChange}
              />
            </div>
            <ul className="imgList">
              {images.map(
                (image) =>
                  !isVideo(image.url) && (
                    <li
                      key={image.id}
                      onClick={() => {
                        if (overlayMode === "mainImage") {
                          handleCheckMainImage(image);
                        } else {
                          handleCheckImages(image);
                        }
                      }}
                    >
                      <Icon
                        name="check circle"
                        className={
                          (overlayMode === "mainImage"
                            ? mainImage
                            : checkImages
                          ).some((checkImage) => checkImage.id === image.id)
                            ? "on"
                            : ""
                        }
                      />
                      <img src={image.url} alt="" />
                    </li>
                  )
              )}
            </ul>
            <div className="loadBtn">
              <Button
                primary
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                さらに読み込む
              </Button>
            </div>
          </div>
          {/* 右カラム */}
          <div className="hashtagData">
            <div className="imgBox">
              {/* 現在、選択中の画像 */}
              <div className="nowImg">
                <p>現在、選択中の画像</p>
                {nowImage && (
                  <>
                    <figure>
                      <img src={nowImage.url} alt="" />
                    </figure>
                    <ul className="hashtagArea">
                      {nowImage?.hashtags?.map((hashtag) => (
                        <li key={hashtag.id}>{hashtag.text}</li>
                      ))}
                    </ul>
                  </>
                )}
              </div>

              {/* 全ての選択した画像 */}
              <div className="selectedImg">
                <ul className="imgList">
                  {(overlayMode === "mainImage" ? mainImage : checkImages).map(
                    (image) => (
                      <li key={image.id}>
                        <img src={image.url} alt="" />
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
            <Button
              primary
              className="addImg"
              onClick={() => {
                setInstructor((prev) => ({
                  ...prev,
                  ...(overlayMode === "mainImage"
                    ? { main_image: mainImage }
                    : { images: checkImages }),
                }));
                setOverlay(false);
              }}
            >
              追加
            </Button>
          </div>
        </div>
      </div>

      {/* 左セクション */}
      <section>
        {instructor && (
          <>
            {/* ヘッダー */}
            <div className="content-header">
              <h3>{instructor.username && instructor.username}</h3>
              <div className="button-group">
                <Button
                  primary
                  content="更新"
                  onClick={handleInstructorDataSubmit}
                  style={{ marginLeft: "1em" }}
                />
              </div>
            </div>

            <Grid container stackable>
              {/* スタジオページ用メイン画像 */}
              <Grid.Row columns={1}>
                <Grid.Column className="mainImage">
                  <p className="form-label">スタジオページ用メイン画像</p>
                  <Image src={instructor?.main_image[0]?.url} size="small" />
                </Grid.Column>
              </Grid.Row>

              {/* スタジオページ用メイン画像編集ボタン */}
              <Grid.Row columns={1}>
                <Grid.Column className="mainImage">
                  <Button
                    primary
                    onClick={() => {
                      setOverlayMode("mainImage");
                      toggleOverlay(true);
                    }}
                  >
                    画像を編集
                  </Button>
                </Grid.Column>
              </Grid.Row>

              {/* 名前 */}
              <Grid.Row columns={1}>
                <Grid.Column>
                  <p className="form-label">名前</p>
                  <Input
                    name="dancer_name"
                    value={instructor.dancer_name || ""}
                    onChange={handleInputChange}
                    style={{ width: "100%" }}
                  />
                </Grid.Column>
              </Grid.Row>
              {/*  Instagram */}
              <Grid.Row columns={1}>
                <Grid.Column>
                  <p className="form-label">Instagram</p>
                  <Input
                    name="instagram"
                    value={instructor.instagram || ""}
                    onChange={handleInputChange}
                    style={{ width: "100%" }}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* YouTube ID */}
              <Grid.Row columns={1}>
                <Grid.Column>
                  <div className="youtube">
                    <p className="form-label">YouTube ID</p>
                    {movieInputs}
                  </div>
                </Grid.Column>
              </Grid.Row>
              {/* プロフィール */}
              <Grid.Row columns={1}>
                <Grid.Column>
                  <p className="form-label">プロフィール</p>
                  <Form>
                    <TextArea
                      name="profile"
                      value={instructor.profile || ""}
                      onChange={handleInputChange}
                      rows={5}
                      placeholder="プロフィール"
                    />
                  </Form>
                </Grid.Column>
              </Grid.Row>
              {/* 画像 */}
              <Grid.Row columns={1}>
                <Grid.Column>
                  <p className="form-label">画像</p>
                  <SortImage
                    checkImages={checkImages}
                    setCheckImages={setCheckImages}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* 画像追加ボタン */}
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Button
                    primary
                    onClick={() => {
                      toggleOverlay(true);
                    }}
                  >
                    画像を追加
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        )}
      </section>

      {/* 右セクション */}
      <section>
        <VisualComponent
          onUpdate={handleUpdate}
          hashtagText={instructor.username}
        />
      </section>
    </>
  );
}

export default InstructorEditPage;
