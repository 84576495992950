import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

function useBodyClass() {
  const location = useLocation();

  useEffect(() => {
    // 現在のパスをハイフン区切りに変換(3個目以降の「/」からは切り捨て)
    const baseClass = location.pathname
      .split("/")
      .filter((part) => part)
      .slice(0, 2)
      .join("-")
      .replace(/^\//, "");

    // 存在しなければ'home'
    const className = baseClass === "" ? "home" : baseClass;
    document.body.classList.add(className);

    // クリーンアップ関数
    return () => {
      document.body.classList.remove(className);
    };
  }, [location.pathname]);

  return null;
}

export default useBodyClass;
