import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Link,
  useLocation,
} from "react-router-dom";
import { Menu, Icon, Header } from "semantic-ui-react";
import { useAuth } from "./contexts/AuthContext";
import TopPage from "./pages/management/TopPage";
import LoginPage from "./pages/auth/LoginPage";
import CustomerListPage from "./pages/management/CustomerListPage";
import FreeCustomerListPage from "./pages/management/FreeCustomerListPage";
import RemoveCustomerListPage from "./pages/management/RemoveCustomerListPage";
import SendMailPage from "./pages/management/SendMailPage";
import RecitalCustomer2025ListPage from "./pages/management/RecitalCustomer2025ListPage";
import WebsiteManagementPage from "./pages/website/WebsiteManagementPage";
import StudioListPage from "./pages/website/studio/StudioListPage";
import StudioEditPage from "./pages/website/studio/StudioEditPage";
import InstructorListPage from "./pages/website/instructor/InstructorListPage";
import InstructorEditPage from "./pages/website/instructor/InstructorEditPage";
import LessonListPage from "./pages/website/lesson/LessonListPage";
import LessonEditPage from "./pages/website/lesson/LessonEditPage";
import NewsListPage from "./pages/website/news/NewsListPage";
import NewsEditPage from "./pages/website/news/NewsEditPage";
import VisualPage from "./pages/website/visual/VisualPage";
import QaPage from "./pages/website/qa/QaPage";
import FaqListPage from "./pages/website/faq/FaqListPage";
import FaqEditPage from "./pages/website/faq/FaqEditPage";
import AnalyticsPage from "./pages/website/analytics/AnalyticsPage";
import CampaignListPage from "./pages/website/campaign/CampaignListPage";
import CampaignEditPage from "./pages/website/campaign/CampaignEditPage";
import ReviewPage from "./pages/website/review/ReviewPage";
import useBodyClass from "./hooks/useBodyClass";

function App() {
  const { isLoggedIn, logout } = useAuth();

  const handleLogout = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/logout/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      console.log(data);
      await logout();
      window.location.href = "/login";
    } catch (error) {
      alert("ログアウトに失敗しました。");
    }
  };

  const SidebarLayout = ({ children, menuType }) => {
    const location = useLocation();

    // 管理画面
    const managementMenu = (
      <>
        <Menu.Item
          as={Link}
          to="/"
          className={location.pathname === "/" ? "on" : ""}
        >
          <Icon name="dashboard" />
          <span>ダッシュボード</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/management/customer-list"
          className={
            location.pathname === "/management/customer-list" ? "on" : ""
          }
        >
          <Icon name="users" />
          <span>生徒一覧</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/management/free-customer-list"
          className={
            location.pathname === "/management/free-customer-list" ? "on" : ""
          }
        >
          <Icon name="clipboard list" />
          <span>体験申込者一覧</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/management/remove-customer-list"
          className={
            location.pathname === "/management/remove-customer-list" ? "on" : ""
          }
        >
          <Icon name="user delete" />
          <span>退会者一覧</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/management/send-mail"
          className={location.pathname === "/management/send-mail" ? "on" : ""}
        >
          <Icon name="mail" />
          <span>メール送信</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/management/recital-customer2025-list"
          className={
            location.pathname === "/management/recital-customer2025-list"
              ? "on"
              : ""
          }
        >
          <Icon name="users" />
          <span>発表会参加者一覧</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/website/studio-list"
          className={location.pathname === "/website/studio-list" ? "on" : ""}
        >
          <Icon name="desktop" />
          <span>WEBサイト管理</span>
        </Menu.Item>
        <Menu.Item as="a" onClick={handleLogout}>
          <Icon name="log out" />
          <span>ログアウト</span>
        </Menu.Item>
      </>
    );

    // WEBサイト管理
    const websiteMenu = (
      <>
        <Menu.Item
          as={Link}
          to="/website/studio-list"
          className={location.pathname === "/website/studio-list" ? "on" : ""}
        >
          <Icon name="building" />
          <span> スタジオ</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/website/instructor-list"
          className={
            location.pathname === "/website/instructor-list" ? "on" : ""
          }
        >
          <Icon name="child" />
          <span> インストラクター</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/website/lesson-list"
          className={location.pathname === "/website/lesson-list" ? "on" : ""}
        >
          <Icon name="calendar check" />
          <span> レッスン</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/website/visual"
          className={location.pathname === "/website/visual" ? "on" : ""}
        >
          <Icon name="camera" />
          <span> 写真 / 動画</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/website/qa"
          className={location.pathname === "/website/qa" ? "on" : ""}
        >
          <Icon name="question" />
          <span>Q & A</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/website/faq-list"
          className={location.pathname === "/website/faq-list" ? "on" : ""}
        >
          <Icon name="question" />
          <span>よくある質問</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/website/news-list"
          className={location.pathname === "/website/news-list" ? "on" : ""}
        >
          <Icon name="edit" />
          <span>投稿</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/website/review"
          className={location.pathname === "/website/review" ? "on" : ""}
        >
          <Icon name="comment" />
          <span> 口コミ</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/website/campaign-list"
          className={location.pathname === "/website/campaign-list" ? "on" : ""}
        >
          <Icon name="bullhorn" />
          <span>キャンペーン</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/website/analytics"
          className={location.pathname === "/website/analytics" ? "on" : ""}
        >
          <Icon name="chart bar" />
          <span> 解析</span>
        </Menu.Item>
        <Menu.Item as={Link} to="/">
          <Icon name="arrow left" />
          <span> 管理画面に戻る</span>
        </Menu.Item>
      </>
    );

    // アコーディオンメニューサンプル
    const accordionMenuItem = (
      <>
        <Menu.Item
          to="/"
          className="accordion-item"
          style={{ display: "none" }}
        >
          <Icon name="arrow left" />
          <span>テスト</span>
        </Menu.Item>
        <ul className="children">
          <Link>
            <li>インストラクターごと売上分析</li>
          </Link>
          <Link className="on">
            <li>テスト2</li>
          </Link>
          <Link>
            <li>テスト3</li>
          </Link>
          <Link>
            <li>テスト4</li>
          </Link>
        </ul>
      </>
    );

    return (
      <>
        <Header className="top" textAlign="left">
          SLASH ADMIN
        </Header>
        <main>
          <div className="sidebar">
            {menuType === "website" ? websiteMenu : managementMenu}
          </div>
          <div className="inner">{children}</div>
        </main>
      </>
    );
  };

  const RenderPage = () => {
    useBodyClass();

    if (!isLoggedIn) {
      return <Navigate to="/login" replace />;
    }

    return (
      <Routes>
        <Route
          path="/"
          element={
            <SidebarLayout menuType="management">
              <TopPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/management/customer-list"
          element={
            <SidebarLayout menuType="management">
              <CustomerListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/management/free-customer-list"
          element={
            <SidebarLayout menuType="management">
              <FreeCustomerListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/management/remove-customer-list"
          element={
            <SidebarLayout menuType="management">
              <RemoveCustomerListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/management/send-mail"
          element={
            <SidebarLayout menuType="management">
              <SendMailPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/management/recital-customer2025-list"
          element={
            <SidebarLayout menuType="management">
              <RecitalCustomer2025ListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/website-management"
          element={
            <SidebarLayout menuType="website">
              <WebsiteManagementPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/studio-list"
          element={
            <SidebarLayout menuType="website">
              <StudioListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/studio-edit/:studioId"
          element={
            <SidebarLayout menuType="website">
              <StudioEditPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/instructor-list"
          element={
            <SidebarLayout menuType="website">
              <InstructorListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/instructor-edit/:instructorId"
          element={
            <SidebarLayout menuType="website">
              <InstructorEditPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/lesson-list"
          element={
            <SidebarLayout menuType="website">
              <LessonListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/lesson-edit/:studioId"
          element={
            <SidebarLayout menuType="website">
              <LessonEditPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/visual"
          element={
            <SidebarLayout menuType="website">
              <VisualPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/qa"
          element={
            <SidebarLayout menuType="website">
              <QaPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/faq-list"
          element={
            <SidebarLayout menuType="website">
              <FaqListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/faq-edit/:id?"
          element={
            <SidebarLayout menuType="website">
              <FaqEditPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/news-list"
          element={
            <SidebarLayout menuType="website">
              <NewsListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/news-edit/:id?"
          element={
            <SidebarLayout menuType="website">
              <NewsEditPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/review"
          element={
            <SidebarLayout menuType="website">
              <ReviewPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/campaign-list"
          element={
            <SidebarLayout menuType="website">
              <CampaignListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/campaign-edit/:id?"
          element={
            <SidebarLayout menuType="website">
              <CampaignEditPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/analytics"
          element={
            <SidebarLayout menuType="website">
              <AnalyticsPage />
            </SidebarLayout>
          }
        />
      </Routes>
    );
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="*" element={<RenderPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
