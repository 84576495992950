import React, { useState } from "react";
import { Icon } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

function SortImage({ checkImages, setCheckImages }) {
  // 画像ソート用
  const sensors = useSensors(useSensor(PointerSensor));
  const [isDragging, setIsDragging] = useState(false);

  // 画像の順番を変更した時の処理
  const handleDragEnd = (event) => {
    const { active, over } = event;

    setIsDragging(false);

    if (active.id !== over.id) {
      setCheckImages((prev) => {
        return arrayMove(prev, active.id, over.id);
      });
    }
  };

  // 画像ソートのコンポーネント
  const SortableItem = ({ id, children }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({
        id,
      });

    const style = {
      transform: transform
        ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
        : undefined,
      transition,
    };

    const handlePointerDown = (e) => {
      if (!e.target.closest(".deleteBtn")) {
        listeners.onPointerDown(e);
        setIsDragging(true);
      }
    };

    return (
      <li ref={setNodeRef} style={style} {...attributes}>
        <div className="sortable-item">
          <div className="drag-area" onPointerDown={handlePointerDown}>
            {children}
          </div>
        </div>
      </li>
    );
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={checkImages?.map((_, index) => String(index)) || []}
        strategy={verticalListSortingStrategy}
      >
        <ul className="imgList">
          {checkImages?.map((image, index) => (
            <SortableItem key={image.id} id={String(index)}>
              <div className="image-container">
                <img src={image.url} alt="" className="drag-area" />
                <div
                  className="deleteBtn"
                  onClick={(e) => {
                    e.stopPropagation();
                    setCheckImages((prev) => {
                      // 画像をstateから削除
                      return prev.filter(
                        (prevImage) => prevImage.id !== image.id
                      );
                    });
                  }}
                >
                  <Icon name="times circle" />
                </div>
              </div>
            </SortableItem>
          ))}
        </ul>
      </SortableContext>
    </DndContext>
  );
}

export default React.memo(SortImage);
