import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Button,
  Input,
  TextArea,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import jaLocale from "@fullcalendar/core/locales/ja";

function SendMailPage() {
  const [studios, setStudios] = useState(null); // スタジオ
  const [selectedDate, setSelectedDate] = useState(null); // 選択された日付
  const [selectedStudios, setSelectedStudios] = useState([]); // 選択されたスタジオ
  const [selectedLessons, setSelectedLessons] = useState([]); // 選択されたレッスン
  const [customers, setCustomers] = useState([]); // 顧客
  const [trialCustomers, setTrialCustomers] = useState([]); // 体験顧客
  const [trial, setTrial] = useState(false); // 体験
  const [exist, setExist] = useState(false); // 既存
  const [title, setTitle] = useState(""); // 件名
  const [content, setContent] = useState(""); // 本文

  const [isLoading, setIsLoading] = useState(false); // ローディング

  const [showTemplateOverlay, setShowTemplateOverlay] = useState(false); // オーバーレイの表示
  const [mailTemplates, setMailTemplates] = useState([]); // メールテンプレート
  const [selectedTemplate, setSelectedTemplate] = useState(null); // 選択したテンプレート

  const [showNewTempOverlay, setShowNewTempOverlay] = useState(false); // テンプレート新規作成オーバーレイの表示
  const [newTemplateTitle, setNewTemplateTitle] = useState(null); // 新規定型文のタイトル
  const [newTemplateSubject, setNewTemplateSubject] = useState(null); // 新規定型文の件名
  const [newTemplateBody, setNewTemplateBody] = useState(null); // 新規定型文の本文

  // メールテンプレートの取得
  const getMailTemplates = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_mail_templates/`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setMailTemplates(data);
    } catch (error) {
      console.error("詳細の取得に失敗しました", error);
    }
  }, []);

  // メールテンプレートを選択する関数
  const selectTemplate = (template) => {
    setSelectedTemplate(template);
  };

  // メールテンプレートを適用する関数
  const applyTemplate = () => {
    if (selectedTemplate) {
      setTitle(selectedTemplate.subject);
      setContent(selectedTemplate.body);
      setShowTemplateOverlay(false);
    }
  };

  // 最初にメールテンプレートを取得
  useEffect(() => {
    getMailTemplates();
  }, []);

  // スタジオデータの取得
  const getStudios = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_target_week_studios/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          date: selectedDate,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setStudios(data);
    } catch (error) {
      console.error("詳細の取得に失敗しました", error);
    }
  }, [selectedDate]);

  // 体験顧客取得
  const getTrialCustomers = useCallback(async () => {
    if (!trial) {
      setTrialCustomers([]);
      return;
    }
    if (selectedLessons.length === 0) return;

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}/api/get_trial_customers_from_lesson/`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            lesson_ids: selectedLessons,
            selected_date: selectedDate,
          }),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setTrialCustomers(data);
    } catch (error) {
      console.error("詳細の取得に失敗しました", error);
    }
  }, [trial, selectedLessons, selectedDate]);

  // 顧客の取得
  const getCustomers = useCallback(async () => {
    if (!exist) {
      setCustomers([]);
      return;
    }
    if (selectedLessons.length === 0) return;

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_customers_from_lesson/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          lesson_ids: selectedLessons,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setCustomers(data);
    } catch (error) {
      console.error("詳細の取得に失敗しました", error);
    }
  }, [exist, selectedLessons, selectedDate]);

  // メール送信
  const sendMail = useCallback(async () => {
    // バリデーション
    if (trialCustomers.length === 0 && customers.length === 0) {
      alert("送信対象が存在しません。");
      return;
    }
    if (title === "" || content === "") {
      alert("件名と本文は必ず入力して下さい");
      return;
    }

    const confirmSend = window.confirm(
      `この内容で送信してよろしいですか？\n\n件名: ${title}\n\n本文: \n${content}`
    );
    if (confirmSend) {
      setIsLoading(true);
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(
          `${apiUrl}/api/send_mail_trial_and_exist_customers/`,
          {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              customers: customers,
              trial_customers: trialCustomers,
              subject: title,
              body: content,
            }),
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        setIsLoading(false);
        alert("メールを送信しました。");
      } catch (error) {
        setIsLoading(false);
        console.error("詳細の取得に失敗しました", error);
      }
    }
  }, [customers, trialCustomers, title, content]);

  useEffect(() => {
    getTrialCustomers();
  }, [getTrialCustomers]);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  // 日付クリック時に動く関数
  const handleDateClick = (arg) => {
    if (selectedDate === arg.dateStr) return;

    // 前に選択されている日付をリセット
    if (selectedDate) {
      const selectedElement = document.querySelector(
        `[data-date="${selectedDate}"]`
      );
      if (selectedElement) {
        selectedElement.classList.remove("selected-date");
      }
    }
    setSelectedDate(arg.dateStr);
    setCustomers([]);
    setTrialCustomers([]);
  };

  // 選択レッスンの変更
  const changeSelectedLessons = (studioName) => {
    // スタジオのステート操作
    setSelectedStudios((prevStudios) => {
      const updatedStudios = [...prevStudios];
      const index = updatedStudios.indexOf(studioName);
      if (index === -1) {
        updatedStudios.push(studioName);
      } else {
        updatedStudios.splice(index, 1);
      }
      return updatedStudios;
    });

    // レッスンのステート操作
    const newLessons = studios[studioName];
    setSelectedLessons((prevLessons) => {
      const updatedLessons = [...prevLessons];
      newLessons.forEach((lessonId) => {
        const index = updatedLessons.indexOf(lessonId);
        if (index === -1) {
          updatedLessons.push(lessonId);
        } else {
          updatedLessons.splice(index, 1);
        }
      });
      return updatedLessons;
    });

    setExist(false);
    setTrial(false);
    setCustomers([]);
    setTrialCustomers([]);
  };

  // 選択された日付にクラス属性を付与し、スタジオリストを取得
  useEffect(() => {
    if (selectedDate) {
      document
        .querySelector(`[data-date="${selectedDate}"]`)
        .classList.add("selected-date");

      getStudios();
    }
  }, [selectedDate]);

  // オーバーレイを表示する関数
  const toggleTemplateOverlay = () => {
    setShowTemplateOverlay(!showTemplateOverlay);
  };

  // 新規作成オーバーレイを表示する関数
  const toggleNewTemplateOverlay = () => {
    setShowNewTempOverlay(!showNewTempOverlay);
  };

  // 定型文新規作成
  const createMailTemplate = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/create_mail_template/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: newTemplateTitle,
          subject: newTemplateSubject,
          body: newTemplateBody,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }

      alert("保存しました");
      getMailTemplates(); // メールテンプレート再取得
      toggleNewTemplateOverlay();
    } catch (error) {
      console.error("データの送信中にエラーが発生しました:", error);
    }
  };

  // 定型文削除
  const deleteMailTemplate = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/delete_mail_template/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          template_id: selectedTemplate.id,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }

      alert("削除しました");
      getMailTemplates(); // メールテンプレート再取得
      setSelectedTemplate(null);
    } catch (error) {
      console.error("データの送信中にエラーが発生しました:", error);
    }
  };

  return (
    <>
      {/* ローディング */}
      {isLoading && (
        <Dimmer active>
          <Loader size="large" />
        </Dimmer>
      )}

      {/* 定型文選択オーバーレイ */}
      {showTemplateOverlay && (
        <div className="overlayWrap" onClick={toggleTemplateOverlay}>
          <div
            className="overlayContent1"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="ovHeader">
              <h3>定型文選択</h3>
              <div>
                {selectedTemplate && (
                  <>
                    <Button
                      className="deleteBtn"
                      onClick={() => {
                        deleteMailTemplate();
                      }}
                    >
                      削除
                    </Button>
                  </>
                )}

                <Button
                  primary
                  onClick={() => {
                    toggleTemplateOverlay();
                    toggleNewTemplateOverlay();
                  }}
                >
                  新規作成
                </Button>
              </div>
            </div>

            {/* メールテンプレートのリスト */}
            <div className="templateList">
              {mailTemplates.map((template, index) => (
                <p key={index} onClick={() => selectTemplate(template)}>
                  {template.title}
                </p>
              ))}
            </div>

            {/* 選択したテンプレートの詳細 */}
            <div className="templateDetail">
              {selectedTemplate && (
                <>
                  <h4>【件名】: {selectedTemplate.subject}</h4>
                  <h4>【本文】: </h4>
                  <p>
                    {selectedTemplate.body.split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                </>
              )}
            </div>

            <div className="btnArea">
              <Button primary onClick={applyTemplate}>
                選択
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* 定型文新規作成オーバーレイ */}
      {showNewTempOverlay && (
        <div className="overlayWrap" onClick={toggleNewTemplateOverlay}>
          <div
            className="overlayContent2"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <h3>定型文新規作成</h3>

            {/* 名前 */}
            <Grid.Row columns={1}>
              <Grid.Column>
                <p className="form-label">定型文タイトル</p>
                <Input
                  name="template_title"
                  placeholder="タイトル"
                  value={newTemplateTitle}
                  onChange={(e) => setNewTemplateTitle(e.target.value)}
                />
              </Grid.Column>
            </Grid.Row>
            {/* 名前 */}
            <Grid.Row columns={1}>
              <Grid.Column>
                <p className="form-label">件名</p>
                <Input
                  name="template_subject"
                  placeholder="件名"
                  value={newTemplateSubject}
                  onChange={(e) => setNewTemplateSubject(e.target.value)}
                />
              </Grid.Column>
            </Grid.Row>
            {/* 名前 */}
            <Grid.Row columns={1}>
              <Grid.Column>
                <p className="form-label">本文</p>
                <TextArea
                  name="template_body"
                  rows={25}
                  placeholder="本文"
                  value={newTemplateBody}
                  onChange={(e) => setNewTemplateBody(e.target.value)}
                />
              </Grid.Column>
            </Grid.Row>

            <div className="btnArea">
              <Button
                primary
                onClick={() => {
                  createMailTemplate();
                }}
              >
                保存
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* 左セクション */}
      <section>
        {/* 日付選択 */}
        <p>日付選択</p>
        <div className="calendar">
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            height={430}
            dateClick={handleDateClick}
            locale={jaLocale}
          />
        </div>

        {/* スタジオ選択 */}
        <Grid.Row columns={1}>
          <Grid.Column>
            <p className="form-label">スタジオ選択</p>
            <div className="buttonWrap">
              {studios &&
                Object.keys(studios).map((studioName, index) => (
                  <Button
                    key={index}
                    className={selectedStudios.includes(studioName) ? "on" : ""}
                    onClick={() => {
                      changeSelectedLessons(studioName);
                    }}
                  >
                    {studioName}
                  </Button>
                ))}
            </div>
          </Grid.Column>
        </Grid.Row>

        {/* 送信対象 */}
        <Grid.Row columns={1}>
          <Grid.Column>
            <p className="form-label">送信対象</p>
            <div className="buttonWrap">
              <Button
                className={trial ? "on" : ""}
                onClick={() => {
                  if (selectedLessons.length > 0) {
                    setTrial(!trial);
                  }
                }}
              >
                体験
              </Button>
              <Button
                className={exist ? "on" : ""}
                onClick={() => {
                  if (selectedLessons.length > 0) {
                    setExist(!exist);
                  }
                }}
              >
                会員
              </Button>
            </div>
          </Grid.Column>
        </Grid.Row>
      </section>

      {/* 中央セクション */}
      <section>
        {/* 定型文ボタン */}
        <div className="alert">
          <p>※ 休講メールを送る場合は先にHPでレッスンを閉鎖しているか確認</p>
        </div>
        <Grid.Row columns={1}>
          <Grid.Column className="tempBtn">
            <Button primary onClick={toggleTemplateOverlay}>
              定型文
            </Button>
          </Grid.Column>
        </Grid.Row>

        {/* タイトル */}
        <Grid.Row columns={1}>
          <Grid.Column>
            <p className="form-label">タイトル</p>
            <Input
              fluid
              placeholder="タイトル"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Grid.Column>
        </Grid.Row>

        {/* 本文 */}
        <Grid.Row columns={1}>
          <Grid.Column>
            <p className="form-label">本文</p>
            <TextArea
              placeholder="本文"
              name="content"
              rows={25}
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </Grid.Column>
        </Grid.Row>

        {/* 送信ボタン */}
        <Grid.Row columns={1}>
          <Grid.Column className="sendBtn">
            <Button
              primary
              onClick={() => {
                sendMail();
              }}
            >
              送信
            </Button>
          </Grid.Column>
        </Grid.Row>
      </section>

      {/* 右セクション */}
      <section className="send-list">
        <h4>体験</h4>
        {trialCustomers &&
          trialCustomers.map((trialCustomer, index) => (
            <p key={index}>
              {trialCustomer.name}
              <br />
              {trialCustomer.email}
            </p>
          ))}
        <h4>会員</h4>
        {customers &&
          customers.map((customer, index) => (
            <p key={index}>
              {customer.name}
              <br />
              {customer.email}
            </p>
          ))}
      </section>
    </>
  );
}

export default SendMailPage;
