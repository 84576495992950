import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Header, Grid, GridRow, GridColumn } from "semantic-ui-react";
import "../../../index.css";
import { PREFECTURES } from "../../../constants/studioConstants";

function StudioListPage() {
  const [studios, setStudios] = useState([]);

  useEffect(() => {
    // 店舗の取得
    const fetchStudiosData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/api/get_studios/`);
        if (!response.ok) {
          throw new Error("ネットワークレスポンスが異常です。");
        }
        const data = await response.json();
        setStudios(sortStudiosByPrefectureKey(data, PREFECTURES));
      } catch (error) {
        console.error("データの取得中にエラーが発生しました:", error);
      }
    };
    // 地域ごとのグループ分け
    function groupStudiosByPrefecture(studios) {
      return studios.reduce((acc, studio) => {
        if (!acc[studio.prefecture]) {
          acc[studio.prefecture] = [];
        }
        acc[studio.prefecture].push(studio);
        return acc;
      }, {});
    }
    // 都道府県コードで並べ替え
    function sortStudiosByPrefectureKey(studios, prefectureOrder) {
      const grouped = groupStudiosByPrefecture(studios);
      const sortedGroup = {};
      prefectureOrder.forEach((prefecture) => {
        if (grouped[prefecture.text]) {
          sortedGroup[prefecture.text] = grouped[prefecture.text];
        }
      });
      return sortedGroup;
    }

    fetchStudiosData();
  }, []);

  // 近い店舗の更新
  const updateNearbyStudios = async () => {
    if (
      window.confirm("古い店舗間の距離情報は破棄されます。本当に実行しますか？")
    ) {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/api/update_nearby_studios/`, {
          method: "POST",
        });
        if (!response.ok) {
          throw new Error("ネットワークレスポンスが異常です。");
        }
        alert("店舗間の距離情報を更新しました。");
      } catch (error) {
        console.error("データの更新中にエラーが発生しました:", error);
        alert("店舗間の距離情報の更新に失敗しました。");
      }
    }
  };

  return (
    <>
      <Button primary onClick={updateNearbyStudios}>
        店舗間の距離情報を更新
      </Button>
      {Object.keys(studios).map((prefecture) => (
        <section key={prefecture}>
          <div>
            <Header as="h4">{prefecture}</Header>
            <Grid>
              <GridRow>
                {studios[prefecture].map((studio) => (
                  <GridColumn className="button-item" key={studio.id}>
                    <Link
                      to={`/website/studio-edit/${studio.id}`}
                      className="studio-link"
                    >
                      <Button color="grey">
                        <p>{studio.name}</p>
                      </Button>
                    </Link>
                  </GridColumn>
                ))}
              </GridRow>
            </Grid>
          </div>
        </section>
      ))}
    </>
  );
}

export default StudioListPage;
