import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Checkbox,
  Form,
  Grid,
  GridColumn,
  GridRow,
  Icon,
  Image,
  Input,
  Label,
  Segment,
  TextArea,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import VisualComponent from "../../../components/website/VisualComponent";
import SortImage from "../../../components/website/SortImage";

const NewsEditPage = () => {
  const { id } = useParams(); // ルートパラメータの`id`を取得
  const navigate = useNavigate();
  const location = useLocation();

  const [parentUpdated, setParentUpdated] = useState(false); // 子コンポーネントで更新が必要な場合にtrueにする

  const [overlay, setOverlay] = useState(false); // オーバーレイの制御
  const [overlayMode, setOverlayMode] = useState(null); // オーバーレイのモードを制御
  const [images, setImages] = useState([]); // 画像リスト
  const [checkImages, setCheckImages] = useState([]); // 選択した画像
  const [eyecatch, setEyecatch] = useState([]); // 選択したアイキャッチ画像
  const [nowImage, setNowImage] = useState([]); // 現在選択中の画像

  const [news, setNews] = useState({
    id: null,
    is_secret: false,
    title: "",
    text: "",
    eyecatch: [],
    images: [],
  });

  const [hashtagMasterList, setHashtagMasterList] = useState([]);
  const [filteredHashtags, setFilteredHashtags] = useState([]);
  const [selectedHashTags, setSelectedHashTags] = useState([]);
  const [hashtagInput, setHashtagInput] = useState("");
  const [deletedTags, setDeletedTags] = useState([]);

  const [page, setPage] = useState(1); // ページネーション用
  const [searchWord, setSearchWord] = useState(""); // 検索文字

  // 子コンポーネントに渡して更新を検知する関数
  const handleUpdate = useCallback(() => {
    // 更新が必要なときに実行する処理
    fetchNewsData();
    fetchHashtagForTarget();
    fetchHashtagMasterData();
    fetchImagesData();
  }, []);

  // ニュースの取得
  const fetchNewsData = useCallback(async () => {
    if (!id) return;
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_news/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setNews(data);
      setCheckImages(data.images);
      setEyecatch(data.eyecatch);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, [id]);

  // ハッシュタグマスタの取得
  const fetchHashtagMasterData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_hashtag_master/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setHashtagMasterList(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  // ハッシュタグの取得
  const fetchHashtagForTarget = useCallback(async () => {
    if (!id) return;
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_hashtag_for_target/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          target_id: id,
          type: "News",
        }),
      });
      const data = await response.json();
      setSelectedHashTags(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, [id]);

  // 画像リストの取得
  const fetchImagesData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_images/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          page: page,
          search_word: searchWord,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      if (page === 1) {
        setImages(data);
      } else {
        setImages((prevImages) => {
          const newImages = data.filter(
            (newImage) =>
              !prevImages.some((prevImage) => prevImage.id === newImage.id)
          );
          return [...prevImages, ...newImages];
        });
      }
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, [page]);

  // 画像の検索
  const searchImagesData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_images/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          page: 1,
          search_word: searchWord,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setImages(data);
      setPage(1);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  // 入力欄を基にタグをstateに保存する関数
  const handleAddHashtag = () => {
    if (hashtagInput) {
      // 一致するハッシュタグを検索
      const matchedHashtag = hashtagMasterList.find(
        (tag) => tag.text === hashtagInput
      );

      setSelectedHashTags((prevTags) => {
        // 既に削除されたタグのリストにあるか確認
        const existingDeletedTagIndex = deletedTags.findIndex(
          (tag) => tag.text === hashtagInput
        );

        if (existingDeletedTagIndex !== -1) {
          // 削除されたタグのリストからタグを戻す
          const existingDeletedTag = deletedTags[existingDeletedTagIndex];
          setDeletedTags((prevDeletedTags) =>
            prevDeletedTags.filter(
              (_, index) => index !== existingDeletedTagIndex
            )
          );
          return [...prevTags, existingDeletedTag];
        } else {
          // 既に選択されたタグのリストにあるか確認
          const isTagAlreadyAdded = prevTags.some(
            (tag) => tag.text === hashtagInput
          );

          if (!isTagAlreadyAdded) {
            // 一致するものがある場合はそのレコードを、なければ新しいオブジェクトを追加
            const newTag = matchedHashtag
              ? {
                  id: null,
                  master_id: matchedHashtag.id,
                  text: hashtagInput,
                  order: null,
                }
              : {
                  id: null,
                  master_id: null,
                  text: hashtagInput,
                  order: null,
                };
            return [...prevTags, newTag];
          }
        }

        return prevTags; // 既に存在する場合は何も追加しない
      });

      setHashtagInput("");
    }
  };

  // タグを削除する関数
  const handleRemoveTag = (tagToRemove) => {
    setSelectedHashTags((prevTags) =>
      prevTags.filter((tag) => tag !== tagToRemove)
    );
    setDeletedTags((prevDeletedTags) => [...prevDeletedTags, tagToRemove]);
  };

  // タグ入力欄でエンターを押下した際にstateに保持する関数を呼び出す
  const handleHashtagInputKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAddHashtag();
      setHashtagInput("");
    }
  };

  // インプットの状態を監視
  const handleHashtagInputChange = (event) => {
    setHashtagInput(event.target.value);
  };

  // ハッシュタグの保存
  const saveHashTags = async (news_id) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/save_hashtags/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          target_id: news_id,
          hashtags: selectedHashTags,
          type: "News",
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  // stateにハッシュタグをセット
  const handleSelectedHashTags = (hashtagMaster) => {
    // 既に削除されたタグのリストにあるか確認
    const existingDeletedTagIndex = deletedTags.findIndex(
      (tag) => tag.master_id === hashtagMaster.id
    );

    if (existingDeletedTagIndex !== -1) {
      // 削除されたタグのリストからタグを戻す
      const existingDeletedTag = deletedTags[existingDeletedTagIndex];
      setDeletedTags((prevDeletedTags) =>
        prevDeletedTags.filter((_, index) => index !== existingDeletedTagIndex)
      );
      setSelectedHashTags((prevTags) => [...prevTags, existingDeletedTag]);
    } else {
      // 既に選択されたタグのリストに含まれているか確認
      const exists = selectedHashTags.some(
        (tag) => tag.master_id === hashtagMaster.id
      );

      if (!exists) {
        // 存在しない場合は新たに追加
        setSelectedHashTags((prevTags) => [
          ...prevTags,
          {
            id: null,
            master_id: hashtagMaster.id,
            text: hashtagMaster.text,
            order: null,
          },
        ]);
      }
    }
  };

  // inputタグが更新された時にstateを更新する関数
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNews((prev) => ({ ...prev, [name]: value }));
  };

  // チェックボックス押下時
  const handleCheckboxChange = (e, { name, checked }) => {
    setNews((prev) => ({ ...prev, [name]: checked }));
  };

  // 検索欄の状態を監視
  const handleSearchChange = (event) => {
    setSearchWord(event.target.value);
  };

  // submitボタンが押下された時に動く関数
  const handleSubmit = async (e) => {
    e.preventDefault();
    setParentUpdated(false);
    const formData = new FormData();

    formData.append("news_id", news.id);
    formData.append("title", news.title);
    formData.append("text", news.text);
    formData.append("is_secret", news.is_secret);
    const imageIds = checkImages.map((image) => image.id).join(",");
    formData.append("image_ids", imageIds);
    const eyecatchIds = news.eyecatch.map((image) => image.id).join(",");
    formData.append("eyecatch_ids", eyecatchIds);

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/news_edit/`, {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      await saveHashTags(data.news.id);
      const newPath = `/website/news-edit/${data.news.id}`;
      if (location.pathname === newPath) {
        // 既に必要なページにいる場合、stateを更新
        fetchNewsData();
        fetchImagesData();
        setNowImage([]);
        setParentUpdated(true);
        fetchHashtagForTarget();
        fetchHashtagMasterData();
        alert("更新しました");
      } else {
        // 新規作成ページにいる場合、編集ページへ遷移
        alert("保存しました");
        navigate(newPath);
      }
    } catch (error) {
      alert("登録に失敗しました");
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // 画像選択時にstateにセット
  const handleCheckImages = (image) => {
    // 現在選択中の画像にセット
    setNowImage(image);

    setCheckImages((prev) => {
      // 既に同じ画像が存在するか確認
      const isImageAlreadyAdded = prev.some(
        (prevImage) => prevImage.id === image.id
      );
      if (!isImageAlreadyAdded) {
        // 画像が存在しない場合、追加
        return [...prev, image];
      }
      // 画像が既に存在する場合、その画像をstateから削除
      return prev.filter((prevImage) => prevImage.id !== image.id);
    });
  };

  // アイキャッチ選択時にstateにセット
  const handleCheckEyecatch = (image) => {
    // 現在選択中の画像にセット
    setNowImage(image);

    setEyecatch((prev) => {
      // 既に同じ画像が存在するか確認
      const isImageAlreadyAdded = prev.some(
        (prevImage) => prevImage.id === image.id
      );
      if (!isImageAlreadyAdded) {
        // 画像が存在しない場合、入れ替え
        return [image];
      }
      // 画像が既に存在する場合、その画像をstateから削除
      return prev.filter((prevImage) => prevImage.id !== image.id);
    });
  };

  // オーバーレイの表示/非表示
  const toggleOverlay = (bool) => {
    setOverlay(bool);
  };

  // ビデオかどうか
  const isVideo = (url) => {
    // 動画ファイルの拡張子リスト
    const videoExtensions = [".mp4", ".webm", ".ogg"];
    // URLの末尾の拡張子を確認
    return videoExtensions.some((extension) => url.endsWith(extension));
  };

  // 入力されたテキストに基づいて hashtagMasterList からフィルタリング
  useEffect(() => {
    const results = hashtagMasterList.filter((hashtag) =>
      hashtag.text.toLowerCase().includes(hashtagInput.toLowerCase())
    );
    setFilteredHashtags(results);
  }, [hashtagInput, hashtagMasterList]);

  // ニュースの取得
  useEffect(() => {
    fetchNewsData();
  }, [fetchNewsData]);

  // タグマスタの取得
  useEffect(() => {
    fetchHashtagMasterData();
  }, [fetchHashtagMasterData]);

  // ニュースのidを基にハッシュタグを取得
  useEffect(() => {
    fetchHashtagForTarget();
  }, [fetchHashtagForTarget]);

  // 画像リストの取得
  useEffect(() => {
    fetchImagesData();
  }, [fetchImagesData]);

  return (
    <>
      {/* 画像オーバーレイ */}
      <div className={overlay ? "overlay open" : "overlay"}>
        <div className="ov grid2">
          <Icon
            name="times circle"
            className="cancelBtn"
            onClick={() => {
              toggleOverlay(false);
            }}
          />
          {/* 左カラム */}
          <div className="imgArea overflow">
            <div className="searchWrap">
              <Input
                icon={{
                  name: "search",
                  link: true,
                  onClick: () => searchImagesData(),
                }}
                placeholder="検索"
                onChange={handleSearchChange}
              />
            </div>
            <ul className="imgList">
              {images.map(
                (image) =>
                  !isVideo(image.url) && (
                    <li
                      key={image.id}
                      onClick={() => {
                        if (overlayMode === "eyecatch") {
                          handleCheckEyecatch(image);
                        } else {
                          handleCheckImages(image);
                        }
                      }}
                    >
                      <Icon
                        name="check circle"
                        className={
                          (overlayMode === "eyecatch"
                            ? eyecatch
                            : checkImages
                          ).some((checkImage) => checkImage.id === image.id)
                            ? "on"
                            : ""
                        }
                      />
                      <img src={image.url} alt="" />
                    </li>
                  )
              )}
            </ul>
            <div className="loadBtn">
              <Button
                primary
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                さらに読み込む
              </Button>
            </div>
          </div>
          {/* 右カラム */}
          <div className="hashtagData">
            <div className="imgBox">
              {/* 現在、選択中の画像 */}
              <div className="nowImg">
                <p>現在、選択中の画像</p>
                {nowImage && (
                  <>
                    <figure>
                      <img src={nowImage.url} alt="" />
                    </figure>
                    <ul className="hashtagArea">
                      {nowImage?.hashtags?.map((hashtag) => (
                        <li key={hashtag.id}>{hashtag.text}</li>
                      ))}
                    </ul>
                  </>
                )}
              </div>

              {/* 全ての選択した画像 */}
              <div className="selectedImg">
                <ul className="imgList">
                  {(overlayMode === "eyecatch" ? eyecatch : checkImages).map(
                    (image) => (
                      <li key={image.id}>
                        <img src={image.url} alt="" />
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
            <Button
              primary
              className="addImg"
              onClick={() => {
                setNews((prev) => ({
                  ...prev,
                  ...(overlayMode === "eyecatch"
                    ? { eyecatch: eyecatch }
                    : { images: checkImages }),
                }));
                setOverlay(false);
              }}
            >
              追加
            </Button>
          </div>
        </div>
      </div>

      {/* 左セクション */}
      <section>
        <div className="content-header">
          <div className="button-group">
            <Checkbox
              label="限定公開"
              name="is_secret"
              checked={news.is_secret}
              onClick={handleCheckboxChange}
            />
            <Button primary onClick={handleSubmit}>
              {id ? "更新" : "作成"}
            </Button>
          </div>
        </div>
        <Grid container stackable>
          {/* アイキャッチ */}
          <Grid.Row columns={1}>
            <Grid.Column className="eyecatch">
              <p className="form-label">アイキャッチ</p>
              <Image src={news?.eyecatch[0]?.url} size="small" />
            </Grid.Column>
          </Grid.Row>

          {/* アイキャッチ編集ボタン */}
          <Grid.Row columns={1}>
            <Grid.Column className="eyecatch">
              <Button
                primary
                onClick={() => {
                  setOverlayMode("eyecatch");
                  toggleOverlay(true);
                }}
              >
                アイキャッチ編集
              </Button>
            </Grid.Column>
          </Grid.Row>

          {/* タイトル */}
          <Grid.Row columns={1}>
            <Grid.Column>
              <p className="form-label">タイトル</p>
              <Input
                fluid
                placeholder="タイトル"
                name="title"
                value={news?.title}
                onChange={handleInputChange}
              />
            </Grid.Column>
          </Grid.Row>

          {/* 本文 */}
          <Grid.Row columns={1}>
            <Grid.Column>
              <p className="form-label">本文</p>
              <Form>
                <TextArea
                  placeholder="本文"
                  name="text"
                  value={news?.text}
                  rows={15}
                  onChange={handleInputChange}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>

          {/* 画像 */}
          <Grid.Row columns={1}>
            <Grid.Column>
              <p className="form-label">画像</p>
              <SortImage
                checkImages={checkImages}
                setCheckImages={setCheckImages}
              />
            </Grid.Column>
          </Grid.Row>

          {/* 画像追加ボタン */}
          <Grid.Row columns={1}>
            <Grid.Column>
              <Button
                primary
                onClick={() => {
                  setOverlayMode("image");
                  toggleOverlay(true);
                }}
              >
                画像を追加
              </Button>
            </Grid.Column>
          </Grid.Row>

          {/* ハッシュタグ */}
          <Grid.Row columns={1}>
            <Grid.Column className="hashtag-wrap">
              <p className="form-label">ハッシュタグ</p>
              <GridRow>
                <GridColumn width={16}>
                  {selectedHashTags &&
                    selectedHashTags.map((selectedHashTag, index) => (
                      <Label key={index}>
                        {selectedHashTag.text}
                        <Icon
                          name="delete"
                          onClick={() => handleRemoveTag(selectedHashTag)}
                        />
                      </Label>
                    ))}
                  <Input
                    fluid
                    placeholder="タグを入力"
                    name="hashtags"
                    value={hashtagInput}
                    onChange={handleHashtagInputChange}
                    onKeyPress={handleHashtagInputKeyPress}
                  />
                  <Segment className="scrollable-container">
                    {filteredHashtags.map((hashtagMaster) => (
                      <Label
                        key={hashtagMaster.id}
                        as="a"
                        onClick={() => handleSelectedHashTags(hashtagMaster)}
                      >
                        {hashtagMaster.text}
                      </Label>
                    ))}
                  </Segment>
                </GridColumn>
              </GridRow>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </section>

      {/* 右セクション */}
      <section>
        <VisualComponent
          onUpdate={handleUpdate}
          hashtagText={null}
          parentUpdated={parentUpdated}
        />
      </section>
    </>
  );
};

export default NewsEditPage;
