import React, { useEffect } from "react";
import { Input, Icon } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

// PriceAreaコンポーネント
const PriceArea = ({ prices, setPrices }) => {
  const handlePriceChange = (e, index) => {
    const { name, value } = e.target;
    const newPrices = [...prices];
    newPrices[index][name.split("_")[0]] = value;
    setPrices(newPrices);
  };

  const handleAdd = () => {
    setPrices([...prices, { name: "", before: "", after: "" }]);
  };

  const handleRemove = (index) => {
    setPrices(prices.filter((_, i) => i !== index));
  };

  return (
    <div>
      {prices?.map((_, index) => (
        <div className="priceArea" key={index}>
          <Input
            fluid
            placeholder="値下げ項目名"
            value={prices[index]?.name}
            name={`name_${index}`}
            onChange={(e) => {
              handlePriceChange(e, index);
            }}
          />
          <Input
            fluid
            placeholder="値下げ前"
            value={prices[index]?.before}
            name={`before_${index}`}
            onChange={(e) => {
              handlePriceChange(e, index);
            }}
          />
          <Input
            fluid
            placeholder="値下げ後"
            value={prices[index]?.after}
            name={`after_${index}`}
            onChange={(e) => {
              handlePriceChange(e, index);
            }}
          />

          <div className="addRemoveBtn">
            <Icon name="plus circle" onClick={() => handleAdd()} />
          </div>

          <div className="addRemoveBtn">
            {prices?.length > 1 && (
              <Icon name="minus circle" onClick={() => handleRemove(index)} />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default React.memo(PriceArea);
