import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Label,
  Segment,
  TextArea,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { formatReviewerInfo } from "../../../utils/formatReviewerInfo";

function ReviewPage() {
  const [reviews, setReviews] = useState([]);
  const [reviewId, setReviewId] = useState(null);
  const [reviewerInfo, setReviewerInfo] = useState("");
  const [content, setContent] = useState("");
  const [reply, setReply] = useState("");
  const [selectedHashTags, setSelectedHashTags] = useState([]);
  const [deletedTags, setDeletedTags] = useState([]);
  const [hashtagInput, setHashtagInput] = useState("");
  const [hashtagMasterList, setHashtagMasterList] = useState([]);
  const [filteredHashtags, setFilteredHashtags] = useState([]);

  // 口コミリストの取得
  const fetchReviews = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_reviews/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setReviews(data);
      console.log(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  // submitボタンが押下された時に動く関数
  const handleSubmit = async (e) => {
    if (!content || !reviewerInfo) {
      alert("未入力の項目があります");
      return;
    }
    e.preventDefault();
    const formData = new FormData();

    formData.append("review_id", reviewId);
    formData.append("reviewer_info", reviewerInfo);
    formData.append("content", content);
    formData.append("reply", reply);

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/save_review/`, {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const reviewId = data.review_id;

      fetchReviews();
      setReviewId(null);
      setReviewerInfo("");
      setContent("");
      setReply("");
      saveHashTags(reviewId);
      alert("口コミを保存しました");
    } catch (error) {
      alert("登録に失敗しました");
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // 入力欄を基にタグをstateに保存する関数
  const handleAddHashtag = () => {
    if (hashtagInput) {
      // 一致するハッシュタグを検索
      const matchedHashtag = hashtagMasterList.find(
        (tag) => tag.text === hashtagInput
      );

      setSelectedHashTags((prevTags) => {
        // 既に削除されたタグのリストにあるか確認
        const existingDeletedTagIndex = deletedTags.findIndex(
          (tag) => tag.text === hashtagInput
        );

        if (existingDeletedTagIndex !== -1) {
          // 削除されたタグのリストからタグを戻す
          const existingDeletedTag = deletedTags[existingDeletedTagIndex];
          setDeletedTags((prevDeletedTags) =>
            prevDeletedTags.filter(
              (_, index) => index !== existingDeletedTagIndex
            )
          );
          return [...prevTags, existingDeletedTag];
        } else {
          // 既に選択されたタグのリストにあるか確認
          const isTagAlreadyAdded = prevTags.some(
            (tag) => tag.text === hashtagInput
          );

          if (!isTagAlreadyAdded) {
            // 一致するものがある場合はそのレコードを、なければ新しいオブジェクトを追加
            const newTag = matchedHashtag
              ? {
                  id: null,
                  master_id: matchedHashtag.id,
                  text: hashtagInput,
                  order: null,
                }
              : {
                  id: null,
                  master_id: null,
                  text: hashtagInput,
                  order: null,
                };
            return [...prevTags, newTag];
          }
        }

        return prevTags; // 既に存在する場合は何も追加しない
      });

      setHashtagInput("");
    }
  };

  // タグを削除する関数
  const handleRemoveTag = (tagToRemove) => {
    setSelectedHashTags((prevTags) =>
      prevTags.filter((tag) => tag !== tagToRemove)
    );
    setDeletedTags((prevDeletedTags) => [...prevDeletedTags, tagToRemove]);
  };

  // タグ入力欄でエンターを押下した際にstateに保持する関数を呼び出す
  const handleHashtagInputKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAddHashtag();
      setHashtagInput("");
    }
  };

  // インプットの状態を監視
  const handleHashtagInputChange = (event) => {
    setHashtagInput(event.target.value);
  };

  // ハッシュタグの保存
  const saveHashTags = async (reviewId) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/save_hashtags/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          target_id: reviewId,
          hashtags: selectedHashTags,
          type: "Review",
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      fetchHashtagMasterData();
      setSelectedHashTags([]);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  // stateにハッシュタグをセット
  const handleSelectedHashTags = (hashtagMaster) => {
    // 既に削除されたタグのリストにあるか確認
    const existingDeletedTagIndex = deletedTags.findIndex(
      (tag) => tag.master_id === hashtagMaster.id
    );

    if (existingDeletedTagIndex !== -1) {
      // 削除されたタグのリストからタグを戻す
      const existingDeletedTag = deletedTags[existingDeletedTagIndex];
      setDeletedTags((prevDeletedTags) =>
        prevDeletedTags.filter((_, index) => index !== existingDeletedTagIndex)
      );
      setSelectedHashTags((prevTags) => [...prevTags, existingDeletedTag]);
    } else {
      // 既に選択されたタグのリストに含まれているか確認
      const exists = selectedHashTags.some(
        (tag) => tag.master_id === hashtagMaster.id
      );

      if (!exists) {
        // 存在しない場合は新たに追加
        setSelectedHashTags((prevTags) => [
          ...prevTags,
          {
            id: null,
            master_id: hashtagMaster.id,
            text: hashtagMaster.text,
            order: null,
          },
        ]);
      }
    }
  };

  // ハッシュタグマスタの取得
  const fetchHashtagMasterData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_hashtag_master/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setHashtagMasterList(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  // 入力されたテキストに基づいて hashtagMasterList からフィルタリング
  useEffect(() => {
    const results = hashtagMasterList.filter((hashtag) =>
      hashtag.text.toLowerCase().includes(hashtagInput.toLowerCase())
    );
    setFilteredHashtags(results);
  }, [hashtagInput, hashtagMasterList]);

  // タグマスタの取得
  useEffect(() => {
    fetchHashtagMasterData();
  }, []);

  useEffect(() => {
    fetchReviews();
  }, [fetchReviews]);

  const [sectionWidth, setSectionWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      const width = document.querySelector(".inner").offsetWidth;
      setSectionWidth(width);
    };

    window.addEventListener("resize", updateWidth);
    updateWidth();

    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  useEffect(() => {
    const reviewForm = document.querySelector(".reviewForm");
    if (reviewForm) {
      reviewForm.style.width = `${sectionWidth}px`;
    }
  }, [sectionWidth]);

  return (
    <>
      <div className="reviewForm">
        <Header as="h4">{reviewId == null ? "新規作成" : "更新"}</Header>
        <Grid.Row columns={1}>
          <Grid.Column>
            <p className="form-label">投稿者</p>
            <Input
              fluid
              placeholder="投稿者情報"
              name="reviewer_info"
              value={reviewerInfo}
              onChange={(e) => setReviewerInfo(e.target.value)}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={1}>
          <Grid.Column>
            <p className="form-label">投稿内容</p>
            <Form>
              <TextArea
                placeholder="投稿内容"
                name="content"
                value={content}
                rows={5}
                onChange={(e) => setContent(e.target.value)}
              />
            </Form>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={1}>
          <Grid.Column>
            <p className="form-label">返信内容</p>
            <Form>
              <TextArea
                placeholder="返信内容"
                name="reply"
                value={reply}
                rows={5}
                onChange={(e) => setReply(e.target.value)}
              />
            </Form>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={1}>
          <Grid.Column className="hashtag-wrap">
            <p className="form-label">ハッシュタグ</p>
            <Grid>
              <Grid.Row columns={1}>
                <Grid.Column width={13} className="hashtag">
                  {selectedHashTags &&
                    selectedHashTags.map((selectedHashTag, index) => (
                      <Label key={index}>
                        {selectedHashTag.text}
                        <Icon
                          name="delete"
                          onClick={() => handleRemoveTag(selectedHashTag)}
                        />
                      </Label>
                    ))}
                </Grid.Column>
                <Grid.Column width={3}>
                  <div className="button-group"></div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid.Row>
              <Grid.Column width={16} className="hashtag-master">
                <Input
                  fluid
                  placeholder="タグを入力"
                  name="hashtags"
                  value={hashtagInput}
                  onChange={handleHashtagInputChange}
                  onKeyPress={handleHashtagInputKeyPress}
                />
                <Segment className="scrollable-container">
                  {filteredHashtags.map((hashtagMaster) => (
                    <Label
                      key={hashtagMaster.id}
                      as="a"
                      onClick={() => handleSelectedHashTags(hashtagMaster)}
                    >
                      {hashtagMaster.text}
                    </Label>
                  ))}
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={1}>
          <Grid.Column>
            <Button primary onClick={handleSubmit}>
              {reviewId == null ? "新規作成" : "更新"}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </div>
      <section>
        <h2>口コミ</h2>
        <Grid container stackable className="sectionWidth">
          {reviews && (
            <>
              {reviews.map((review) => (
                <section
                  key={review.id}
                  onClick={() => {
                    setReviewId(review.id);
                    setReviewerInfo(
                      review.reviewer_info ||
                        (review.customer
                          ? formatReviewerInfo(review.customer.age)
                          : "")
                    );
                    setContent(review.content);
                    setReply(review.reply ? review.reply : "");
                    setSelectedHashTags(review.hashtags);
                  }}
                  className={
                    reviewId === review.id && content === review.content
                      ? "on"
                      : ""
                  }
                >
                  <Header as="h4">
                    {review.reviewer_info ||
                      (review.customer ? `顧客ID: ${review.customer.id}` : "")}
                  </Header>
                  <p>{review.content}</p>
                </section>
              ))}
            </>
          )}
        </Grid>

        <Button
          className="reset"
          primary
          onClick={() => {
            setReviewId(null);
            setReviewerInfo("");
            setContent("");
          }}
        >
          ＋
        </Button>
      </section>
    </>
  );
}

export default ReviewPage;
