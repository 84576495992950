import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardGroup,
  Checkbox,
  Dropdown,
  Form,
  Grid,
  Icon,
  Image,
  Input,
  Select,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { PREFECTURES } from "../../../constants/studioConstants";
import VisualComponent from "../../../components/website/VisualComponent";
import SortImage from "../../../components/website/SortImage";

function StudioEditPage() {
  const { studioId } = useParams();
  const [studio, setStudio] = useState({});
  const [isPublished, setIsPublished] = useState(false);

  // 子コンポーネントに渡して更新を検知する関数
  const handleUpdate = useCallback(() => {
    // 更新が必要なときに実行する処理
    fetchStudioData();
    fetchImagesData();
  }, []);

  // ドロップダウンのアイテムリスト
  const [detailsIconImages, setDetailsIconImages] = useState([{}]);

  // 選択されたドロップダウン
  const [selectedItem, setSelectedItem] = useState([]);
  // ドロップダウンの開閉状態
  const [dropdownOpen, setDropdownOpen] = useState([]);

  const [overlay, setOverlay] = useState(false); // オーバーレイの制御
  const [images, setImages] = useState([]); // 画像リスト
  const [checkImages, setCheckImages] = useState([]); // 選択した画像
  const [nowImage, setNowImage] = useState([]); // 現在選択中の画像

  const [page, setPage] = useState(1); // ページネーション用
  const [searchWord, setSearchWord] = useState(""); // 検索文字

  // その他の詳細情報を変更
  const handleDetailsChange = (index, field, value) => {
    const updatedDetails = Array.isArray(studio.details)
      ? [...studio.details]
      : [];
    if (updatedDetails[index]) {
      updatedDetails[index][field] = value;
    } else {
      updatedDetails[index] = { [field]: value };
    }
    setStudio({
      ...studio,
      details: updatedDetails,
    });
    if (field === "details_icon" || field === "details_text") {
      const newSelectedItems = [...selectedItem];
      if (field === "details_icon") {
        newSelectedItems[index] = detailsIconImages.find(
          (item) => item.id === value
        );
      } else if (!newSelectedItems[index]) {
        newSelectedItems[index] = detailsIconImages[0] || {};
      }
      setSelectedItem(newSelectedItems);
    }
  };

  // その他の詳細情報を削除
  const handleDetailsDelete = (index) => {
    const updatedDetails = [...studio.details];
    updatedDetails.splice(index, 1);
    setStudio({
      ...studio,
      details: updatedDetails,
    });
    const newSelectedItems = [...selectedItem];
    newSelectedItems.splice(index, 1);
    setSelectedItem(newSelectedItems);
    const newDropdownOpen = [...dropdownOpen];
    newDropdownOpen.splice(index, 1);
    setDropdownOpen(newDropdownOpen);
  };

  // その他の詳細情報を追加
  const handleDetailsAdd = () => {
    const updatedDetails = Array.isArray(studio.details)
      ? [...studio.details]
      : [];
    const newDetails = {
      details_icon: detailsIconImages[0]?.id || "",
      details_text: "",
    };
    updatedDetails.push(newDetails);
    setStudio({
      ...studio,
      details: updatedDetails,
    });
    const newSelectedItems = [...selectedItem, detailsIconImages[0] || {}];
    setSelectedItem(newSelectedItems);
    const newDropdownOpen = [...dropdownOpen, false];
    setDropdownOpen(newDropdownOpen);
  };

  // 入力フォーム変更
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStudio((prevStudio) => ({
      ...prevStudio,
      [name]: value,
    }));
  };

  // ドロップダウン変更
  const handleSelectChange = (e, { name, value }) => {
    setStudio((prevStudio) => ({
      ...prevStudio,
      [name]: value,
    }));
  };

  // 店舗情報を更新
  const handleStudioDataSubmit = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const imageIds = checkImages.map((image) => image.id).join(",");
      const response = await fetch(`${apiUrl}/api/update_studio/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          studio_id: studioId,
          prefecture: studio.prefecture,
          name: studio.name,
          address: studio.address,
          place_name: studio.place_name,
          slug: studio.slug,
          details: studio.details,
          image_ids: imageIds,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }

      alert(data.message);
      fetchStudioData();
    } catch (error) {
      alert(error);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // その他の詳細アイコンのドロップダウン変更時
  const handleItemClick = (item, index) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!item) return;
    const newSelectedItems = [...selectedItem];
    newSelectedItems[index] = item;
    setSelectedItem(newSelectedItems);
    handleDetailsChange(index, "details_icon", item.id);
    setDropdownOpen((prevState) => prevState.map((_, idx) => false));
  };

  // ドロップダウンの開閉
  const toggleDropdown = (index) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  // 公開用トグル
  const handleToggle = async () => {
    if (!isPublished && (studio.lat === null || studio.lng === null)) {
      alert("緯度経度が存在しないため、公開できません。");
      return;
    }
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/update_is_published/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          studio_id: studioId,
          is_published: !isPublished,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setIsPublished(!isPublished);
      console.log(data);
    } catch (error) {
      alert("更新に失敗しました");
      console.error("更新中にエラーが発生しました:", error);
    }
  };

  // ドロップダウンのクリックイベント
  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (!event.target.closest(".ui.dropdown")) {
        // ドロップダウンが開いているかどうかを確認
        const isDropdownOpen = dropdownOpen.some((isOpen) => isOpen);
        if (isDropdownOpen) {
          setDropdownOpen((prevState) => prevState.map(() => false));
        }
      }
    };

    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [dropdownOpen]);

  // スタジオデータの取得
  const fetchStudioData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_studio/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          studio_id: studioId,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
      setStudio(data);
      setCheckImages(data.images);
      setIsPublished(data.is_published);
      const sortedDetailsIconImages = data.details_icon_images.sort(
        (a, b) => a.id - b.id
      );
      setDetailsIconImages(sortedDetailsIconImages);
      if (!data.details || data.details.length === 0) {
        const defaultDetails = [
          {
            details_icon: sortedDetailsIconImages[0]?.id || "",
            details_text: "",
          },
        ];
        setStudio((prevStudio) => ({
          ...prevStudio,
          details: defaultDetails,
        }));
        setSelectedItem([sortedDetailsIconImages[0] || {}]);
        setDropdownOpen([false]);
      } else {
        setDropdownOpen(data.details.map(() => false));
        setSelectedItem(
          data.details.map(
            (details) =>
              sortedDetailsIconImages.find(
                (icon) => icon.id === details.details_icon
              ) || {}
          )
        );
      }
    } catch (error) {
      console.error("詳細の取得に失敗しました", error);
    }
  }, [studioId]);

  useEffect(() => {
    fetchStudioData();
  }, [fetchStudioData]);

  useEffect(() => {
    if (!studio.details || studio.details.length === 0) {
      setDropdownOpen([false]);
    }
  }, [studio.details]);

  // その他の詳細のデフォルト
  const defaultDetailsInput = () => {
    // その他の詳細のデフォルト状態をリセット
    const handleDetailsReset = () => {
      const defaultDetails = {
        details_icon: detailsIconImages[0]?.id || "",
        details_text: "",
      };

      setStudio((prevStudio) => ({
        ...prevStudio,
        details: [defaultDetails],
      }));
      setSelectedItem([detailsIconImages[0] || {}]);
      setDropdownOpen([false]);
    };

    return (
      <ul>
        <li className="icon">
          <div className="dropdown-wrap" onClick={(e) => toggleDropdown(0)(e)}>
            <Dropdown open={dropdownOpen[0]}>
              <Dropdown.Menu>
                {detailsIconImages.map((item) => (
                  <Dropdown.Item
                    key={item.id}
                    onClick={(e) => handleItemClick(item, 0)(e)}
                    image={{
                      avatar: true,
                      src: item.url,
                      style: {
                        borderRadius: 0,
                      },
                    }}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {detailsIconImages.length > 0 && (
              <Image
                src={detailsIconImages[0].url || ""}
                onClick={toggleDropdown(0)}
                style={{
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        </li>
        <li className="text">
          <div className="input-action-wrapper">
            <Input
              className="details-input"
              name="details_text"
              value=""
              onChange={(e) =>
                handleDetailsChange(0, "details_text", e.target.value)
              }
            />
            <div className="action-buttons">
              <Button
                icon="plus circle"
                className="add-details-button"
                onClick={handleDetailsAdd}
              />
              <Button
                icon="minus circle"
                className="delete-details-button"
                onClick={handleDetailsReset}
              />
            </div>
          </div>
        </li>
      </ul>
    );
  };

  // その他の詳細入力行をレンダリング
  const renderDetailsInput = (details, index) => {
    return (
      <ul key={index}>
        <li className="icon">
          <div
            className="dropdown-wrap"
            onClick={(e) => toggleDropdown(index)(e)}
          >
            <Dropdown open={dropdownOpen[index]}>
              <Dropdown.Menu>
                {detailsIconImages.map((item) => (
                  <Dropdown.Item
                    key={item.id}
                    onClick={(e) => handleItemClick(item, index)(e)}
                    image={{
                      avatar: true,
                      src: item.url,
                      style: {
                        borderRadius: 0,
                      },
                    }}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {selectedItem && (
              <Image
                src={selectedItem[index]?.url || ""}
                onClick={toggleDropdown(index)}
                style={{
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        </li>
        <li className="text">
          <div className="input-action-wrapper">
            <Input
              className="details-input"
              name="details_text"
              value={details.details_text || ""}
              onChange={(e) =>
                handleDetailsChange(index, "details_text", e.target.value)
              }
            />
            <div className="action-buttons">
              <Button
                icon="plus circle"
                className="add-details-button"
                onClick={handleDetailsAdd}
              />
              <Button
                icon="minus circle"
                className="delete-details-button"
                onClick={() => handleDetailsDelete(index)}
              />
            </div>
          </div>
        </li>
      </ul>
    );
  };

  // 既存のその他の詳細入力行またはデフォルトのその他の詳細入力行をレンダリング
  const detailsInputs =
    studio.details && studio.details.length > 0
      ? studio.details.map(renderDetailsInput)
      : defaultDetailsInput();

  // ビデオかどうか
  function isVideo(url) {
    // 動画ファイルの拡張子リスト
    const videoExtensions = [".mp4", ".webm", ".ogg"];
    // URLの末尾の拡張子を確認
    return videoExtensions.some((extension) => url.endsWith(extension));
  }

  // オーバーレイの表示/非表示
  const toggleOverlay = (bool) => {
    setOverlay(bool);
  };

  // 検索欄の状態を監視
  const handleSearchChange = (event) => {
    setSearchWord(event.target.value);
  };

  // 画像選択時にstateにセット
  const handleCheckImages = (image) => {
    // 現在選択中の画像にセット
    setNowImage(image);

    setCheckImages((prev) => {
      // 既に同じ画像が存在するか確認
      const isImageAlreadyAdded = prev.some(
        (prevImage) => prevImage.id === image.id
      );
      if (!isImageAlreadyAdded) {
        // 画像が存在しない場合、追加
        return [...prev, image];
      }
      // 画像が既に存在する場合、その画像をstateから削除
      return prev.filter((prevImage) => prevImage.id !== image.id);
    });
  };

  // 画像リストの取得
  const fetchImagesData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_images/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          page: page,
          search_word: searchWord,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      if (page === 1) {
        setImages(data);
      } else {
        setImages((prevImages) => {
          const newImages = data.filter(
            (newImage) =>
              !prevImages.some((prevImage) => prevImage.id === newImage.id)
          );
          return [...prevImages, ...newImages];
        });
      }
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, [page]);

  // 画像リストの取得
  useEffect(() => {
    fetchImagesData();
  }, [fetchImagesData]);

  // 画像の検索
  const searchImagesData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_images/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          page: 1,
          search_word: searchWord,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setImages(data);
      setPage(1);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  const prefectureOptions = PREFECTURES;

  return (
    <>
      {/* 画像オーバーレイ */}
      <div className={overlay ? "overlay open" : "overlay"}>
        <div className="ov grid2">
          <Icon
            name="times circle"
            className="cancelBtn"
            onClick={() => {
              toggleOverlay(false);
            }}
          />
          {/* 左カラム */}
          <div className="imgArea overflow">
            <div className="searchWrap">
              <Input
                icon={{
                  name: "search",
                  link: true,
                  onClick: () => searchImagesData(),
                }}
                placeholder="検索"
                onChange={handleSearchChange}
              />
            </div>
            <ul className="imgList">
              {images &&
                images.map((image) => (
                  <li
                    key={image.id}
                    onClick={() => {
                      handleCheckImages(image);
                    }}
                  >
                    <Icon
                      name="check circle"
                      className={
                        checkImages.some(
                          (checkImage) => checkImage.id === image.id
                        )
                          ? "on"
                          : ""
                      }
                    />
                    {isVideo(image.url) ? (
                      <video width="100%">
                        <source src={image.url} type="video/mp4" />
                      </video>
                    ) : (
                      <img src={image.url} alt="" />
                    )}
                  </li>
                ))}
            </ul>
            <div className="loadBtn">
              <Button
                primary
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                さらに読み込む
              </Button>
            </div>
          </div>
          {/* 右カラム */}
          <div className="hashtagData">
            <div className="imgBox">
              {/* 現在、選択中の画像 */}
              <div className="nowImg">
                <p>現在、選択中の画像</p>
                {nowImage && (
                  <>
                    {nowImage.url && isVideo(nowImage.url) ? (
                      <figure>
                        <video width="100%" controls>
                          <source src={nowImage.url} type="video/mp4" />
                        </video>
                      </figure>
                    ) : (
                      <figure>
                        <img src={nowImage.url} alt="" />
                      </figure>
                    )}
                    <ul className="hashtagArea">
                      {nowImage?.hashtags?.map((hashtag) => (
                        <li key={hashtag.id}>{hashtag.text}</li>
                      ))}
                    </ul>
                  </>
                )}
              </div>

              {/* 全ての選択した画像 */}
              <div className="selectedImg">
                <ul className="imgList">
                  {checkImages.map((image) =>
                    isVideo(image.url) ? (
                      <li key={image.id}>
                        <video width="100%">
                          <source src={image.url} type="video/mp4" />
                        </video>
                      </li>
                    ) : (
                      <li key={image.id}>
                        <img src={image.url} alt="" />
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
            <Button
              primary
              className="addImg"
              onClick={() => {
                setStudio((prev) => ({
                  ...prev,
                  images: checkImages,
                }));
                setOverlay(false);
              }}
            >
              追加
            </Button>
          </div>
        </div>
      </div>

      {/* 左セクション */}
      <section>
        {studio && (
          <>
            {/* ヘッダー */}
            <div className="content-header">
              <h3>{studio.slug && `${studio.slug.toUpperCase()} STUDIO`}</h3>
              <div className="button-group">
                <Form>
                  <Form.Field>
                    <label>公開</label>
                    <Checkbox
                      toggle
                      value={isPublished}
                      checked={isPublished}
                      onChange={handleToggle}
                    />
                  </Form.Field>
                </Form>
                <Button
                  primary
                  content="更新"
                  onClick={handleStudioDataSubmit}
                />
              </div>
            </div>
            <Grid container stackable>
              {/* 地域、店舗名 */}
              <Grid.Row columns={2}>
                <Grid.Column>
                  <p className="form-label">地域</p>
                  <Select
                    name="prefecture"
                    options={prefectureOptions}
                    value={studio.prefecture || ""}
                    onChange={handleSelectChange}
                  />
                </Grid.Column>
                <Grid.Column>
                  <p className="form-label">店舗名</p>
                  <Input
                    name="name"
                    value={studio.name || ""}
                    onChange={handleInputChange}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* 住所、スタジオ */}
              <Grid.Row columns={2}>
                <Grid.Column>
                  <p className="form-label">住所</p>
                  <Input
                    name="address"
                    value={studio.address || ""}
                    onChange={handleInputChange}
                  />
                  <small>
                    {studio.lat && studio.lng
                      ? `緯度: ${studio.lat} 経度: ${studio.lng}`
                      : "緯度経度が存在しません"}
                  </small>
                </Grid.Column>
                <Grid.Column>
                  <p className="form-label">スタジオ</p>
                  <Input
                    name="place_name"
                    value={studio.place_name || ""}
                    onChange={handleInputChange}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* その他詳細 */}
              <Grid.Row columns={1}>
                <div className="details">
                  <p className="form-label">その他の詳細</p>
                  {detailsInputs}
                </div>
              </Grid.Row>
              {/* スラッグ */}
              <Grid.Row columns={2}>
                <Grid.Column>
                  <p className="form-label">スラッグ</p>
                  <Input
                    name="slug"
                    value={studio.slug || ""}
                    onChange={handleInputChange}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* 画像 */}
              <Grid.Row columns={1}>
                <Grid.Column>
                  <p className="form-label">画像</p>
                  <SortImage
                    checkImages={checkImages}
                    setCheckImages={setCheckImages}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* 画像追加ボタン */}
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Button
                    primary
                    onClick={() => {
                      toggleOverlay(true);
                    }}
                  >
                    画像を追加
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        )}
      </section>

      {/* 右セクション */}
      <section>
        <VisualComponent onUpdate={handleUpdate} hashtagText={studio.name} />
      </section>
    </>
  );
}

export default StudioEditPage;
