import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Header } from "semantic-ui-react";

function NewsListPage() {
  const [newsList, setNewsList] = useState([]);

  useEffect(() => {
    const fetchNewsData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/api/get_news_list/`);
        if (!response.ok) {
          throw new Error("ネットワークレスポンスが異常です。");
        }
        const data = await response.json();
        setNewsList(data);
      } catch (error) {
        console.error("データの取得中にエラーが発生しました:", error);
      }
    };

    fetchNewsData();
  }, []);

  return (
    <>
      <Link to={"/website/news-edit"}>新規作成</Link>
      {newsList.map((news) => (
        <Link to={`/website/news-edit/${news.id}`} key={news.id}>
          <section>
            <Header as="h4">
              {news.title}
              <span>(更新時間: {news.updated_at})</span>
              {news.is_secret && <span className="secret">限定公開</span>}
            </Header>
            <p>{news.text}</p>
          </section>
        </Link>
      ))}
    </>
  );
}

export default NewsListPage;
