import React, { useEffect, useState, useCallback } from "react";
import { Button, Form, Grid, Header, Input, TextArea } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

function QaPage() {
  const [questionAnswers, setQuestionAnswers] = useState([]);
  const [qaId, setQaId] = useState(null);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  // よくある質問リストの取得
  const fetchQuestionAnswers = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_question_answers/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setQuestionAnswers(data);
      console.log(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  // submitボタンが押下された時に動く関数
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    console.log(qaId);
    console.log(question);
    console.log(answer);

    formData.append("qa_id", qaId);
    formData.append("question", question);
    formData.append("answer", answer);

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/save_question_answer/`, {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      fetchQuestionAnswers();
      setQaId(null);
      setQuestion("");
      setAnswer("");
      alert("よくある質問を保存しました");
    } catch (error) {
      alert("登録に失敗しました");
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  useEffect(() => {
    fetchQuestionAnswers();
  }, [fetchQuestionAnswers]);

  useEffect(() => {
    console.log(question);
  }, [question]);

  const [sectionWidth, setSectionWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      const width = document.querySelector(".inner").offsetWidth;
      setSectionWidth(width);
    };

    window.addEventListener("resize", updateWidth);
    updateWidth();

    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  useEffect(() => {
    const qaForm = document.querySelector(".qaform");
    if (qaForm) {
      qaForm.style.width = `${sectionWidth}px`;
    }
  }, [sectionWidth]);

  return (
    <>
      <div className="qaform">
        <Header as="h4">{qaId == null ? "新規作成" : "更新"}</Header>
        <Grid.Row columns={1}>
          <Grid.Column>
            <p className="form-label">質問</p>
            <Input
              fluid
              placeholder="質問"
              name="question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={1}>
          <Grid.Column>
            <p className="form-label">回答</p>
            <Form>
              <TextArea
                placeholder="回答"
                name="answer"
                value={answer}
                rows={10}
                onChange={(e) => setAnswer(e.target.value)}
              />
            </Form>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={1}>
          <Grid.Column>
            <Button primary onClick={handleSubmit}>
              {qaId == null ? "新規作成" : "更新"}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </div>
      <section>
        <h2>Q & A</h2>
        <Grid container stackable className="sectionWidth">
          {questionAnswers && (
            <>
              {questionAnswers.map((questionAnswer) => (
                <section
                  key={questionAnswer.id}
                  onClick={() => {
                    setQaId(questionAnswer.id);
                    setQuestion(questionAnswer.question);
                    setAnswer(questionAnswer.answer);
                  }}
                  className={
                    qaId === questionAnswer.id &&
                    answer === questionAnswer.answer
                      ? "on"
                      : ""
                  }
                >
                  <Header as="h4">{questionAnswer.question}</Header>
                  <p>{questionAnswer.answer}</p>
                </section>
              ))}
            </>
          )}
        </Grid>

        <Button
          className="reset"
          primary
          onClick={() => {
            setQaId(null);
            setQuestion("");
            setAnswer("");
          }}
        >
          ＋
        </Button>
      </section>
    </>
  );
}

export default QaPage;
