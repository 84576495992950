import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Header,
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Table,
} from "semantic-ui-react";

function CampaignListPage() {
  const [campaigns, setCampaigns] = useState([]);

  const fetchCampaignsData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_campaigns/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setCampaigns(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  // キャンペーンの取得
  useEffect(() => {
    fetchCampaignsData();
  }, [fetchCampaignsData]);

  // 削除ボタンが押されたときの処理
  const handleDelete = async (id) => {
    // アラートを表示
    const confirmDelete = window.confirm("本当に削除しますか？");
    if (confirmDelete) {
      // OKが選択された場合、APIにリクエストを送る
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/api/campaign_delete/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ campaign_id: id }),
        });
        if (!response.ok) {
          throw new Error("ネットワークレスポンスが異常です。");
        }
        // 削除後のキャンペーンリストを取得
        fetchCampaignsData();
      } catch (error) {
        console.error("データの削除中にエラーが発生しました:", error);
      }
    }
  };

  return (
    <>
      <Link to={"/website/campaign-edit"}>
        <Button primary>新規作成</Button>
      </Link>
      <Table celled inverted selectable>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>ID</TableHeaderCell>
            <TableHeaderCell>スラッグ</TableHeaderCell>
            <TableHeaderCell>タイトル</TableHeaderCell>
            <TableHeaderCell>テキスト</TableHeaderCell>
            <TableHeaderCell></TableHeaderCell>
            <TableHeaderCell></TableHeaderCell>
          </TableRow>
        </TableHeader>

        <TableBody>
          {campaigns.map((campaign) => (
            <TableRow key={campaign.id}>
              <TableCell>{campaign.id}</TableCell>
              <TableCell>{campaign.slug}</TableCell>
              <TableCell>{campaign.title}</TableCell>
              <TableCell>{campaign.text}</TableCell>
              <TableCell>
                {campaign.delete_flg === true && (
                  <Button color="grey" disabled>
                    閉鎖
                  </Button>
                )}
                {campaign.delete_flg === false && (
                  <Link to={`/website/campaign-edit/${campaign.id}`}>
                    <Button primary>編集</Button>
                  </Link>
                )}
              </TableCell>
              <TableCell>
                {campaign.delete_flg === false && (
                  <Button color="red" onClick={() => handleDelete(campaign.id)}>
                    終了
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

export default CampaignListPage;
