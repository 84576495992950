import React from "react";
import { Button, Card, Container, Statistic, Grid } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useLoginCheck } from "../../hooks/useLoginCheck";
import { Chart } from "react-google-charts";

function TopPage() {
  useLoginCheck();

  const navigate = useNavigate();
  const data = [
    ["Task", "Hours per Day"],
    ["Work", 11],
    ["Eat", 2],
    ["Commute", 2],
    ["Watch TV", 2],
    ["Sleep", 7],
  ];

  const options = {
    title: "My Daily Activities",
    pieHole: 0.4,
  };

  return (
    <>
      <Container>
        <h1>ダッシュボード</h1>
        <p>
          ようこそ、ここはダッシュボードです。重要な情報を一目で確認できます。
        </p>
        <Grid>
          <Grid.Column width={8}>
            <Card fluid>
              <Card.Content>
                <Card.Header>統計情報</Card.Header>
                <Card.Description>
                  <Statistic.Group widths="two">
                    <Statistic>
                      <Statistic.Value>748</Statistic.Value>
                      <Statistic.Label>会員数</Statistic.Label>
                    </Statistic>
                    <Statistic>
                      <Statistic.Value>2,548</Statistic.Value>
                      <Statistic.Label>体験者数</Statistic.Label>
                    </Statistic>
                  </Statistic.Group>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column width={8}>
            <Chart
              chartType="PieChart"
              width="100%"
              height="400px"
              data={data}
              options={options}
            />
          </Grid.Column>
        </Grid>
      </Container>
    </>
  );
}

export default TopPage;
