import React, { useState, useEffect } from "react";
import { Button, Container, Grid } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useLoginCheck } from "../../hooks/useLoginCheck";

function FreeCustomerListPage() {
  useLoginCheck();

  const week = ["日", "月", "火", "水", "木", "金", "土"];

  const [customers, setCustomers] = useState([]);
  const [weekdayStudios, setWeekdayStudios] = useState([]);
  const [todayLessons, setTodayLessons] = useState([]);
  const [cancelLessons, setCancelLessons] = useState([]);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [newDate, setNewDate] = useState("");

  // 体験顧客の取得
  const fetchCustomersData = async (studio) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}/api/get_free_customers/?studio=${studio}`
      );
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();

      setCustomers(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  // 絞り込みのボタン取得
  useEffect(() => {
    const fetchGetButton = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/api/get_weekday_studios/`);
        if (!response.ok) {
          throw new Error("ネットワークレスポンスが異常です。");
        }
        const data = await response.json();

        setWeekdayStudios(data.studios);
        setTodayLessons(data.today_lesson);
        setCancelLessons(data.cancel_lesson);
      } catch (error) {
        console.error("データの取得中にエラーが発生しました:", error);
      }
    };

    fetchCustomersData("");
    fetchGetButton();
  }, []);

  // 予約日の変更
  const handleDateChange = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/change_trial_date/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: selectedCustomer.id,
          new_date: newDate,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const updatedCustomer = await response.json(); // APIからのレスポンスを取得

      // customersステートを更新
      setCustomers(
        customers.map((customer) =>
          customer.id === updatedCustomer.id ? updatedCustomer : customer
        )
      );

      alert(`${selectedCustomer.name}様の予約日を変更しました`);
      setOverlayOpen(false);
    } catch (error) {
      console.error("データの送信中にエラーが発生しました:", error);
    }
  };

  // 体験のキャンセル
  const handleDelete = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/cancel_trial_customer/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: selectedCustomer.id,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json(); // APIからのレスポンスを取得

      // customersステートから削除した顧客を削除
      setCustomers(
        customers.filter((customer) => customer.id !== selectedCustomer.id)
      );

      alert(`${selectedCustomer.name}様の予約をキャンセルしました`);
      setOverlayOpen(false);
    } catch (error) {
      console.error("データの送信中にエラーが発生しました:", error);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1>体験申込者一覧</h1>
      </div>

      <div className="studio_btn studio_btn_week">
        {Object.entries(weekdayStudios).map(([key, studios]) => (
          <div className="column" key={key}>
            <div className="ttl">{key}</div>
            {studios.map((studio) => (
              <Button
                key={studio}
                style={
                  new Date().getDay() === week.indexOf(key) &&
                  cancelLessons.includes(studio)
                    ? { backgroundColor: "#e44343" }
                    : new Date().getDay() === week.indexOf(key) &&
                      todayLessons.includes(studio)
                    ? { backgroundColor: "#3c8bdb" }
                    : {}
                }
                onClick={() => fetchCustomersData(studio)}
              >
                {studio}
              </Button>
            ))}
          </div>
        ))}
      </div>

      <Grid className="list-container">
        <Grid.Column width={16}>
          <ul className="customer-grid customer-header">
            <li className="cell">ID</li>
            <li className="cell">名前</li>
            <li className="cell">年齢</li>
            <li className="cell">予約日</li>
            <li className="cell">店舗</li>
            <li className="cell">レッスン</li>
            <li className="cell">申込日</li>
            <li className="cell">メール</li>
            <li className="cell">郵便番号</li>
            <li className="cell">電話</li>
            <li className="cell">交通手段</li>
            <li className="cell">メッセージ</li>
            <li className="cell">編集</li>
          </ul>

          {customers.map((customer) => (
            <ul
              key={customer.id}
              className="customer-grid"
              style={
                new Date().toISOString().split("T")[0] === customer.date &&
                cancelLessons.includes(customer.studio)
                  ? { backgroundColor: "#e44343" }
                  : new Date().toISOString().split("T")[0] === customer.date &&
                    todayLessons.includes(customer.studio)
                  ? { backgroundColor: "#3c8bdb" }
                  : {}
              }
            >
              <li className="cell">{customer.id}</li>
              <li className="cell">{customer.name}</li>
              <li className="cell">{customer.age}</li>
              <li className="cell">{customer.date}</li>
              <li className="cell">{customer.studio}</li>
              <li className="cell">{customer.lesson}</li>
              <li className="cell">{customer.created_at}</li>
              <li className="cell">{customer.mail}</li>
              <li className="cell">{customer.postcode}</li>
              <li className="cell">{customer.tel}</li>
              <li className="cell">{customer.access}</li>
              <li className="cell">{customer.message}</li>
              <li className="cell">
                <Button
                  primary
                  onClick={() => {
                    setOverlayOpen(true);
                    setSelectedCustomer(customer);
                  }}
                >
                  日時変更
                </Button>
              </li>
            </ul>
          ))}
        </Grid.Column>
      </Grid>

      {/* オーバーレイが開いている場合 */}
      {overlayOpen && (
        <div
          className="overlayWrap"
          onClick={() => {
            setOverlayOpen(false);
          }}
        >
          {selectedCustomer && (
            <div
              className="overlayContent"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <label>
                【予約日の変更】
                <br />
                {selectedCustomer.name}
                <br />
                {selectedCustomer.lesson}
                <br />
                {selectedCustomer.date}
                <br />↓
              </label>

              <input
                type="date"
                name="date"
                onChange={(e) => setNewDate(e.target.value)}
              />
              <div className="formGroup">
                <Button primary onClick={handleDateChange}>
                  送信
                </Button>
                <Button color="red" onClick={handleDelete}>
                  削除
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default FreeCustomerListPage;
