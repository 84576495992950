/**
 * 年齢からレビュアーの情報をフォーマットする関数
 * @param {number} age - レビュアーの年齢
 * @returns {string} - フォーマットされたレビュアー情報
 */
export function formatReviewerInfo(age) {
  let description = "";

  // 年代の特定
  const decade = Math.floor(age / 10) * 10;
  if (decade < 10) {
    description = `${age}歳`;
  } else if (decade >= 10 && decade < 20) {
    description = "10代";
  } else if (decade >= 20 && decade < 30) {
    description = "20代";
  } else if (decade >= 30 && decade < 40) {
    description = "30代";
  } else {
    description = decade + "代";
  }

  // 学齢の特定
  if (age < 6) {
    description += " 幼児";
  } else if (age >= 6 && age < 13) {
    description += " 小学生";
  } else if (age >= 13 && age < 16) {
    description += " 中学生";
  } else if (age >= 16 && age < 19) {
    description += " 高校生";
  } else if (age >= 19 && age < 23) {
    description += " 大学生";
  } else {
    description += " 社会人";
  }

  // 性別は常に女性とする
  description += " 女性";

  return description;
}
