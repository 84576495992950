import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Header, Icon, Input } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { DndContext, MouseSensor, useSensor, useSensors } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

function FaqListPage() {
  const [category, setCategory] = useState("");
  const [commonQuestions, setCommonQuestions] = useState([]);
  // 各セクションの開閉状態を管理するstate
  const [openSections, setOpenSections] = useState({});
  // カテゴリーのソートか質問のソートかを判断する変数
  const [sortType, setSortType] = useState(null);

  // クリック判定とドラッグ判定を分けるために、ドラッグ判定を遅延させる
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 5, // 5px ドラッグした時にソート機能を有効にする
    },
  });
  const sensors = useSensors(mouseSensor);

  // セクションの開閉を制御する関数
  const toggleSection = (sectionId) => {
    setOpenSections((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId], // 現在の状態を反転
    }));
  };

  // インプットの状態を監視
  const handleInputChange = (event) => {
    setCategory(event.target.value);
  };

  // submitボタンが押下された時に動く関数
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("category_text", category);

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/save_question_category/`, {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      fetchCommonQuestionsData();
      setCategory("");
      toggleSection("input");
      alert("カテゴリーを追加しました");
    } catch (error) {
      alert("登録に失敗しました");
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // よくある質問リストの取得
  const fetchCommonQuestionsData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_common_question_list/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setCommonQuestions(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  // よくある質問リストの取得
  useEffect(() => {
    fetchCommonQuestionsData();
  }, [fetchCommonQuestionsData]);

  // 並び替えた情報を更新
  const handleSortSubmit = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/sort_common_questions/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          common_questions: commonQuestions,
          sort_type: sortType,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
      fetchCommonQuestionsData();
    } catch (error) {
      alert("更新に失敗しました");
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // 並び替えた情報を更新
  useEffect(() => {
    if (sortType === null) return;

    handleSortSubmit();

    setSortType(null);
  }, [commonQuestions, sortType]);

  useEffect(() => {
    console.log(commonQuestions);
  }, [commonQuestions]);

  // カテゴリーの順番を入れ替える関数
  const handleCategoryDragEnd = useCallback((event) => {
    const { active, over } = event;

    if (!over) return; // 追加: overがnull（範囲外）の場合は処理しない

    setCommonQuestions((questions) => {
      const oldIndex = questions.findIndex(
        (question) => question.id === active.id
      );
      const newIndex = questions.findIndex(
        (question) => question.id === over.id
      );

      // カテゴリーの順番を入れ替える
      if (oldIndex !== newIndex) {
        setSortType("category");
        return arrayMove(questions, oldIndex, newIndex);
      }
      return questions;
    });
  }, []);

  // 各カテゴリー内の質問の順番を入れ替える関数
  const handleQuestionDragEnd = useCallback((event) => {
    const { active, over } = event;

    if (!over) return; // 追加: overがnull（範囲外）の場合は処理しない

    setCommonQuestions((questions) => {
      return questions.map((question) => {
        if (question.faqs.some((faq) => faq.id === active.id)) {
          const oldFaqIndex = question.faqs.findIndex(
            (faq) => faq.id === active.id
          );
          const newFaqIndex = question.faqs.findIndex(
            (faq) => faq.id === over.id
          );
          if (oldFaqIndex !== newFaqIndex) {
            setSortType("question");
            return {
              ...question,
              faqs: arrayMove(question.faqs, oldFaqIndex, newFaqIndex),
            };
          }
        }
        return question;
      });
    });
  }, []);

  // よくある質問のコンポーネント
  const FaqComponent = ({ faq }) => {
    const {
      attributes: faqAttributes,
      listeners: faqListeners,
      setNodeRef: setFaqNodeRef,
      transform: faqTransform,
      isDragging,
    } = useSortable({
      id: faq.id,
    });

    const navigate = useNavigate();

    const handleClick = (event) => {
      navigate(`/website/faq-edit/${faq.id}`);
    };

    return (
      <section
        key={faq.id}
        ref={setFaqNodeRef}
        style={{
          transform: faqTransform
            ? `translate3d(${faqTransform.x}px, ${faqTransform.y}px, 0)`
            : undefined,
        }}
        onClick={handleClick}
        {...faqAttributes}
        {...faqListeners}
      >
        <Header as="h4">{faq.question}</Header>
        <p>{faq.answer}</p>
      </section>
    );
  };

  // カテゴリーのコンポーネント
  const QuestionComponent = ({ commonQuestion, toggleSection, sensors }) => {
    const { attributes, listeners, setNodeRef, transform } = useSortable({
      id: commonQuestion.id,
    });

    const navigate = useNavigate();

    const handleClick = (event) => {
      navigate(`/website/faq-edit?category=${commonQuestion.id}`);
    };

    return (
      <>
        <section
          key={commonQuestion.id}
          ref={setNodeRef}
          style={{
            transform: transform
              ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
              : undefined,
          }}
          onClick={() => toggleSection(commonQuestion.category)}
          {...attributes}
          {...listeners}
        >
          <Header as="h3">{commonQuestion.category}</Header>
          <div
            className={
              openSections[commonQuestion.category] ? "qa-wrap open" : "qa-wrap"
            }
          >
            {/* よくある質問リスト */}
            <DndContext onDragEnd={handleQuestionDragEnd} sensors={sensors}>
              <SortableContext
                items={commonQuestion.faqs}
                strategy={verticalListSortingStrategy}
              >
                {commonQuestion.faqs.map((faq) => (
                  <FaqComponent faq={faq} />
                ))}
              </SortableContext>
            </DndContext>
            <div className="button-wrap" onClick={handleClick}>
              <Link to={`/website/faq-edit?category=${commonQuestion.id}`}>
                <Button className="faq-plus" primary>
                  新規作成
                </Button>
              </Link>
            </div>
          </div>
        </section>
      </>
    );
  };

  return (
    <>
      <DndContext onDragEnd={handleCategoryDragEnd} sensors={sensors}>
        <SortableContext
          items={commonQuestions}
          strategy={verticalListSortingStrategy}
        >
          {/* カテゴリー */}
          {commonQuestions.map((commonQuestion) => (
            <QuestionComponent
              commonQuestion={commonQuestion}
              toggleSection={toggleSection}
              sensors={sensors}
            />
          ))}
        </SortableContext>
      </DndContext>

      {/* 新規カテゴリー */}
      <section className={openSections["input"] ? "input open" : "input"}>
        <Input
          fluid
          placeholder="カテゴリーを入力"
          name="category"
          value={category}
          onChange={handleInputChange}
        />
        <div className="button-wrap">
          <Button className="faq-plus" primary onClick={handleSubmit}>
            保存
          </Button>
        </div>
      </section>

      <Button
        className="category-plus"
        primary
        onClick={() => {
          toggleSection("input");
        }}
      >
        {openSections["input"] ? "ー" : "＋"}
      </Button>
    </>
  );
}

export default FaqListPage;
