import React from "react";

function AnalyticsPage() {
  return (
    <>
      <h2>解析</h2>
    </>
  );
}

export default AnalyticsPage;
