import React, { useState } from "react";
import {
  Button,
  Container,
  Form,
  Grid,
  Header,
  Segment,
  Message,
} from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

function LoginPage() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setIsLoggedIn } = useAuth();
  const [error, setError] = useState(null);

  const handleSubmit = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/login/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      });
      if (!response.ok) {
        setIsLoggedIn(false);
        localStorage.setItem("isLoggedIn", false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
      setIsLoggedIn(true);
      localStorage.setItem("isLoggedIn", true);
      navigate("/");
    } catch (error) {
      setIsLoggedIn(false);
      localStorage.setItem("isLoggedIn", false);
      console.error("ログイン中にエラーが発生しました:", error);
      setError(error);
    }
  };

  return (
    <>
      <Container>
        <Grid
          textAlign="center"
          style={{ height: "100vh" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" color="teal" textAlign="center">
              SLASH ADMIN
            </Header>
            <Form size="large">
              <Segment stacked>
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button color="teal" fluid size="large" onClick={handleSubmit}>
                  Login
                </Button>
              </Segment>
            </Form>
            <div style={{ height: "22px", color: "transparent" }}>
              {error && <Message color="red">ログインに失敗しました。</Message>}
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    </>
  );
}

export default LoginPage;
