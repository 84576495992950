import React, { useState, useEffect, useRef } from "react";
import { Grid, Input, Icon, Button } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useLoginCheck } from "../../hooks/useLoginCheck";

function RecitalCustomer2025ListPage() {
  useLoginCheck();
  const [customers, setCustomers] = useState([]); // カスタマー
  const [instructors, setInstructors] = useState([]); // インストラクター
  const [filteredCustomers, setFilteredCustomers] = useState([]); // 絞り込んだカスタマー
  const [searchKeyword, setSearchKeyword] = useState(""); // 検索ワード
  const [isFilterOpen, setIsFilterOpen] = useState(false); // フィルターエリアの開閉
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  }); // ソート
  const [filters, setFilters] = useState({
    pamphlet_flg: null,
    pickup_flg: null,
    pass_flg: null,
    pickup_area: null,
    payment_date: null,
    number: null,
    instructor: [],
  }); // フィルター
  const searchInputRef = useRef(null);

  // フィルターエリアの開閉
  const toggleFilterArea = () => {
    setIsFilterOpen((prev) => !prev);
  };

  // 顧客の取得
  const fetchCustomersData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_recital2025_customers/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setCustomers(data);
      setFilteredCustomers(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  // インストラクターの取得
  const fetchInstructorData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_instructors/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setInstructors(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  // 初期表示時にインストラクターと参加者のリストを取得
  useEffect(() => {
    fetchInstructorData();
    fetchCustomersData();
  }, []);

  // ソート
  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedCustomers = React.useMemo(() => {
    let sortableCustomers = [...filteredCustomers];
    if (sortConfig.key !== null) {
      sortableCustomers.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (sortConfig.key === "number") {
          aValue = a.numbers.map((num) => num.name).join(", ");
          bValue = b.numbers.map((num) => num.name).join(", ");
        }

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableCustomers;
  }, [filteredCustomers, sortConfig]);

  // 検索
  const handleSearch = (event) => {
    let keyword = searchKeyword;

    if (
      event.type === "click" ||
      (event.key === "Enter" && event.target.value)
    ) {
      const filtered = customers.filter((customer) => {
        return (
          (customer.id && customer.id.toString().includes(keyword)) ||
          (customer.last_name && customer.last_name.includes(keyword)) ||
          (customer.first_name && customer.first_name.includes(keyword)) ||
          (customer.last_name_yomi &&
            customer.last_name_yomi.includes(keyword)) ||
          (customer.first_name_yomi &&
            customer.first_name_yomi.includes(keyword)) ||
          (customer.numbers &&
            customer.numbers.some((number) => number.name.includes(keyword))) ||
          (customer.pamphlet_flg && customer.pamphlet_flg.includes(keyword)) ||
          (customer.pickup_flg && customer.pickup_flg.includes(keyword)) ||
          (customer.pickup_area && customer.pickup_area.includes(keyword)) ||
          (customer.pass_flg && customer.pass_flg.includes(keyword)) ||
          (customer.total_pay &&
            customer.total_pay.toString().includes(keyword)) ||
          (customer.payment_date && customer.payment_date.includes(keyword))
        );
      });
      setFilteredCustomers(filtered);
    }
  };

  // フィルター
  const handleFilter = (key, value) => {
    setFilters((prevFilters) => {
      if (key === "instructor") {
        const newValues = prevFilters[key] ? [...prevFilters[key]] : [];
        const index = newValues.indexOf(value);
        if (index > -1) {
          newValues.splice(index, 1);
        } else {
          newValues.push(value);
        }
        return {
          ...prevFilters,
          [key]: newValues,
        };
      } else {
        return {
          ...prevFilters,
          [key]: prevFilters[key] === value ? null : value,
        };
      }
    });
  };

  useEffect(() => {
    let filtered = customers;
    // インストラクター
    if (filters.instructor.length > 0) {
      filtered = filtered.filter((customer) =>
        filters.instructor.some((instructorId) =>
          customer.numbers.some(
            (number) => number.instructor_id === instructorId
          )
        )
      );
    }
    // ナンバー
    if (filters.number !== null) {
      if (filters.number === "1ナンバーのみ") {
        filtered = filtered.filter((customer) => customer.numbers.length === 1);
      } else if (filters.number === "2ナンバー以上") {
        filtered = filtered.filter((customer) => customer.numbers.length >= 2);
      }
    }
    // アー写撮影
    if (filters.pamphlet_flg !== null) {
      filtered = filtered.filter(
        (customer) => customer.pamphlet_flg === filters.pamphlet_flg
      );
    }
    // オーディション
    if (filters.pickup_flg !== null) {
      filtered = filtered.filter(
        (customer) => customer.pickup_flg === filters.pickup_flg
      );
    }
    // 地域
    if (filters.pickup_area !== null) {
      filtered = filtered.filter(
        (customer) =>
          customer.pickup_area &&
          customer.pickup_area.includes(filters.pickup_area)
      );
    }
    // 関係者パス
    if (filters.pass_flg !== null) {
      filtered = filtered.filter(
        (customer) => customer.pass_flg === filters.pass_flg
      );
    }
    // 支払日
    if (filters.payment_date !== null) {
      if (filters.payment_date === "未払い") {
        filtered = filtered.filter(
          (customer) => customer.payment_date === "未払い"
        );
      } else if (filters.payment_date === "支払済") {
        filtered = filtered.filter(
          (customer) => customer.payment_date !== "未払い"
        );
      }
    }

    setFilteredCustomers(filtered);
  }, [filters, customers]);

  // cmd + fで検索窓にフォーカスする
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.metaKey && event.key === "f") {
        event.preventDefault();
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // ナンバーごとの参加人数のCSV出力の関数
  const exportToCSV = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}/api/export_recital2025_customers_csv/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/csv",
          },
        }
      );
      if (!response.ok) {
        throw new Error("CSVの出力に失敗しました。");
      }
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = "recital_customers_2025.csv";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error("CSVの出力中にエラーが発生しました:", error);
    }
  };

  return (
    <>
      <Grid>
        <h1>発表会参加者一覧</h1>
        <Button primary onClick={exportToCSV}>
          CSV出力
        </Button>
        <Input
          icon={{
            name: "search",
            circular: true,
            link: true,
            onClick: handleSearch,
          }}
          placeholder="Search..."
          value={searchKeyword}
          ref={searchInputRef}
          onChange={(e) => {
            setSearchKeyword(e.target.value);
          }}
          onKeyDown={handleSearch}
        />
      </Grid>
      <Grid>
        <div className="filter-toggle" onClick={toggleFilterArea}>
          <p>
            {isFilterOpen ? (
              <Icon name="triangle down" />
            ) : (
              <Icon name="triangle right" />
            )}{" "}
            フィルター
          </p>
        </div>
      </Grid>
      <Grid>
        {isFilterOpen && (
          <div className="filter-area">
            {/* インストラクター */}
            <div className="filter-content">
              <p>インストラクター</p>
              <ul>
                {instructors.map((instructor) => (
                  <li
                    key={instructor.id}
                    className={
                      filters.instructor.includes(instructor.id) ? "on" : ""
                    }
                    onClick={() => handleFilter("instructor", instructor.id)}
                  >
                    {instructor.username}
                  </li>
                ))}
              </ul>
            </div>
            {/* ナンバー */}
            <div className="filter-content">
              <p>ナンバー</p>
              <ul>
                <li
                  className={filters.number === "1ナンバーのみ" ? "on" : ""}
                  onClick={() => handleFilter("number", "1ナンバーのみ")}
                >
                  1ナンバーのみ
                </li>
                <li
                  className={filters.number === "2ナンバー以上" ? "on" : ""}
                  onClick={() => handleFilter("number", "2ナンバー以上")}
                >
                  2ナンバー以上
                </li>
              </ul>
            </div>
            {/* アー写撮影 */}
            <div className="filter-content">
              <p>アー写撮影</p>
              <ul>
                <li
                  className={filters.pamphlet_flg === "参加する" ? "on" : ""}
                  onClick={() => handleFilter("pamphlet_flg", "参加する")}
                >
                  参加する
                </li>
                <li
                  className={filters.pamphlet_flg === "参加しない" ? "on" : ""}
                  onClick={() => handleFilter("pamphlet_flg", "参加しない")}
                >
                  参加しない
                </li>
              </ul>
            </div>
            {/* オーディション */}
            <div className="filter-content">
              <p>オーディション</p>
              <ul>
                <li
                  className={filters.pickup_flg === "参加する" ? "on" : ""}
                  onClick={() => handleFilter("pickup_flg", "参加する")}
                >
                  参加する
                </li>
                <li
                  className={filters.pickup_flg === "参加しない" ? "on" : ""}
                  onClick={() => handleFilter("pickup_flg", "参加しない")}
                >
                  参加しない
                </li>
              </ul>
            </div>
            {/* 地域 */}
            <div className="filter-content">
              <p>地域</p>
              <ul>
                <li
                  className={filters.pickup_area === "SLASH" ? "on" : ""}
                  onClick={() => handleFilter("pickup_area", "SLASH")}
                >
                  SLASH
                </li>
                <li
                  className={filters.pickup_area === "大阪" ? "on" : ""}
                  onClick={() => handleFilter("pickup_area", "大阪")}
                >
                  大阪
                </li>
                <li
                  className={filters.pickup_area === "奈良" ? "on" : ""}
                  onClick={() => handleFilter("pickup_area", "奈良")}
                >
                  奈良
                </li>
                <li
                  className={filters.pickup_area === "京都" ? "on" : ""}
                  onClick={() => handleFilter("pickup_area", "京都")}
                >
                  京都
                </li>
                <li
                  className={filters.pickup_area === "兵庫" ? "on" : ""}
                  onClick={() => handleFilter("pickup_area", "兵庫")}
                >
                  兵庫
                </li>
              </ul>
            </div>
            {/* 関係者パス */}
            <div className="filter-content">
              <p>関係者パス</p>
              <ul>
                <li
                  className={filters.pass_flg === "購入する" ? "on" : ""}
                  onClick={() => handleFilter("pass_flg", "購入する")}
                >
                  購入する
                </li>
                <li
                  className={filters.pass_flg === "購入しない" ? "on" : ""}
                  onClick={() => handleFilter("pass_flg", "購入しない")}
                >
                  購入しない
                </li>
              </ul>
            </div>
            {/* 支払日 */}
            <div className="filter-content">
              <p>支払日</p>
              <ul>
                <li
                  className={filters.payment_date === "未払い" ? "on" : ""}
                  onClick={() => handleFilter("payment_date", "未払い")}
                >
                  未払い
                </li>
                <li
                  className={filters.payment_date === "支払済" ? "on" : ""}
                  onClick={() => handleFilter("payment_date", "支払済")}
                >
                  支払済
                </li>
              </ul>
            </div>
          </div>
        )}
      </Grid>
      <Grid>
        <Grid.Column width={16}>
          <ul className="customer-grid customer-header">
            <li className="cell" onClick={() => handleSort("id")}>
              ID
              {sortConfig.key === "id" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "id" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("customer_id")}>
              顧客ID
              {sortConfig.key === "customer_id" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "customer_id" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("last_name")}>
              名前
              {sortConfig.key === "last_name" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "last_name" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("last_name_yomi")}>
              フリガナ
              {sortConfig.key === "last_name_yomi" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "last_name_yomi" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("number")}>
              ナンバー
              {sortConfig.key === "number" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "number" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("pamphlet_flg")}>
              アー写撮影
              {sortConfig.key === "pamphlet_flg" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "pamphlet_flg" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("pickup_flg")}>
              オーディション
              {sortConfig.key === "pickup_flg" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "pickup_flg" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("pickup_area")}>
              地域
              {sortConfig.key === "pickup_area" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "pickup_area" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("pass_flg")}>
              関係者パス
              {sortConfig.key === "pass_flg" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "pass_flg" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("total_pay")}>
              金額
              {sortConfig.key === "total_pay" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "total_pay" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
            <li className="cell" onClick={() => handleSort("payment_date")}>
              支払日
              {sortConfig.key === "payment_date" &&
                sortConfig.direction === "ascending" && (
                  <Icon name="triangle up" />
                )}
              {sortConfig.key === "payment_date" &&
                sortConfig.direction === "descending" && (
                  <Icon name="triangle down" />
                )}
            </li>
          </ul>

          {sortedCustomers.map((customer) => (
            <ul key={customer.id} className="customer-grid">
              <li className="cell">{customer.id}</li>
              <li className="cell">{customer.customer_id}</li>
              <li className="cell">{`${customer.last_name} ${customer.first_name}`}</li>
              <li className="cell">{`${customer.last_name_yomi} ${customer.first_name_yomi}`}</li>
              <li className="cell">
                {customer.numbers.map((number) => `${number.name} \n`)}
              </li>
              <li className="cell">{customer.pamphlet_flg}</li>
              <li className="cell">{customer.pickup_flg}</li>
              <li className="cell">{customer.pickup_area}</li>
              <li className="cell">{customer.pass_flg}</li>
              <li className="cell">{customer.total_pay}</li>
              <li className="cell">{customer.payment_date}</li>
            </ul>
          ))}
        </Grid.Column>
      </Grid>
    </>
  );
}

export default RecitalCustomer2025ListPage;
