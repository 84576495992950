import React from "react";
import { Container } from "semantic-ui-react";

function WebsiteManagementPage() {
  return (
    <>
      <Container>
        <h1>サイトの管理ページです</h1>
        <p>
          ようこそ、ここはダッシュボードです。重要な情報を一目で確認できます。
        </p>
      </Container>
    </>
  );
}

export default WebsiteManagementPage;
